import React from 'react'
import './OfferAgreement.css'
export default function OfferAgreement() {
  return (
    <div className='offer_agreement'>
      <div className='offer_agreement_p'>
        Публичная оферта о продаже товаров дистанционным способом

        Индивидуальный предприниматель Кантур Павел Александрович, ОГРНИП 319169000103634 от «21» июня 2019 г., осуществляющий продажу товаров через интернет-магазин «Kantur Store» по адресу www.kanturstore.ru, именуемый в дальнейшем "Продавец", публикует публичную оферту о продаже товаров дистанционным способом (далее - оферта).

        <h2>1. Общие положения</h2>

        <p>1.1. В рамках настоящей оферты Продавец предоставляет Покупателю возможность приобрести для личного, семейного, домашнего или иного использования, не связанного с предпринимательской деятельностью, товары, представленные в интернет-магазине «Kantur Store» по адресу: www.kanturstore.ru (далее - сайт).</p>
        <p>1.2. Продавец предоставляет Покупателю каталоги, фотографии или другие информационные материалы, содержащие полную, достоверную и доступную информацию, характеризующую предлагаемый товар.</p>
        <p>1.3. Покупатель соглашается с условиями продажи товаров, изложенными в настоящей оферте.</p>
        <p>1.4. Продавец имеет право в одностороннем порядке без уведомления Покупателя внести изменения в настоящую оферту, которые вступают в силу по истечении 10 (десяти) календарных дней с момента опубликования новой оферты на сайте.</p>
        <p>1.5. С момента регистрации Покупателя на сайте, оформления Покупателем заказа без авторизации на сайте или по телефону +7(925) 224-79-22 настоящая оферта признается акцептованной.</p>
        <p>1.6. Заключение и расторжение договора розничной купли-продажи товара осуществляется в порядке, предусмотренном Законом от 07.02.1992 N 2300-1 "О защите прав потребителей", Правилами продажи товаров по договору розничной купли-продажи, утвержденными постановлением Правительства РФ от 31 декабря 2020 г. N 2463, ГК РФ. Обязательства Продавца по передаче товара и иные обязательства, связанные с передачей товара, возникают с момента получения Продавцом сообщения Покупателя о намерении заключить договор розничной купли-продажи. Договор розничной купли-продажи товара считается заключенным с момента выдачи Продавцом Покупателю кассового (товарного) чека либо иного документа, подтверждающего оплату товара, получения Продавцом сообщения Покупателя о намерении заключить договор розничной купли-продажи.</p>
        <p>1.7. Сообщая Продавцу свой номер телефона и e-mail, Покупатель дает свое согласие на их использование в целях взаимодействия при покупке товаров у Продавца.</p>
        <p>1.8. Продавец имеет право поручить исполнение заказа третьему лицу.</p>
        <p>1.9. Оформление заказов в интернет-магазине возможно 24 часа 00 мин., 7 дней в неделю, за исключением периодов технического сбоя и регламентных работ.</p>
        <p>1.10. Способы получения заказов размещены на сайте www.kanturstore.ru в разделе «Личный кабинет».</p>
        <p>1.11. Данная оферта распространяется на все виды товаров, представленных на сайте, пока такие товары присутствуют в каталоге интернет-магазина.</p>

        <h2>2. Регистрация на сайте, конфиденциальность и защита персональной информации</h2>

        <p>2.1. Регистрация на сайте является/не является обязательной для оформления заказа.</p>
        <p>2.2. Регистрация на сайте осуществляется с помощью адресом имени, адресом электронной почты и номер мобильного телефона..</p>
        <p>2.3. Продавец обязуется не разглашать полученную от Покупателя информацию.</p>
        <p>2.4. Продавец не несет ответственности за правильность предоставляемой Покупателем информации при регистрации на сайте.</p>
        <p>2.5. Покупатель обязуется не сообщать третьим лицам пароль и логин, указанные им при регистрации на сайте, а также незамедлительно сообщить Продавцу о возникновении подозрений относительно безопасности его логина и пароля путем направления соответствующего сообщения на адрес электронной почты, указанной в разделе «admin@kanturstore.ru» сайта интернет-магазина.</p>

        <h2>3. Порядок совершения покупки</h2>

        <p>3.1. Потребитель оформляет заказ на сайте в разделе обувь, одежда и аксесуары, Sale.</p>
        <p>3.2. Порядок оформления заказа: добавить понравившийся товар в корзину, затем перейти к оплате.</p>
        <p>3.3. После получения Продавцом сообщения Покупателя о намерении заключить договор розничной купли-продажи Продавец предоставляет Покупателю подтверждение заключения договора розничной купли-продажи на условиях настоящей оферты, которое содержит номер заказа или иной способ идентификации заказа, позволяющий Покупателю получить информацию о заключенном договоре розничной купли-продажи и его условиях.</p>
        <p>3.4. В случае аннуляции полностью либо частично оплаченного заказа стоимость аннулированного товара возвращается Продавцом Покупателю следующим способом: безналичным переводом денежных средств на счет указанный Покупателем в течение трех рабочих дней.</p>
        <p>3.5. В случае отсутствия заказанных Покупателем товаров на складе Продавец уведомляет об этом Покупателя, путем телефонного звонка с номера +7(925) 224-79-22.</p>
        <p>3.6. Ответственность за предоставление неверных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем, лежит на Покупателе.</p>

        <h2>4. Порядок доставки, приема товара</h2>

        <p>4.1. Способы доставки товара, службы доставки: СДЭК, Почта России, Boxberry, Курьер.</p>
        <p>4.2. Сроки доставки товара: с момента оформления заказа Покупателем, Продавец в течение 48 часов обязуется отправить нужный товар по адресу указанным Покупателем. Так же если Покупатель находится в г. Москва и выбрал способ доставки «Курьер», то Продавец на следующий день (либо через день) отправляет товар Покупателю по указанному адресу.</p>
        <p>4.3. Территория доставки товара: доставка «Курьер» - г. Москва в пределах МКАД. Доставки СДЭК, Boxberry, Почта России отправляются по всей России.</p>
        <p>4.4. Стоимость доставки товара включает уже в цену указанную на сайте.</p>
        <p>4.5. Порядок доставки товара: после оплаты товара Покупателем, Продавец отправляет товар способом который выбрал Покупатель. Так же покупатель в Личном кабинете, по номеру заказа, может видеть на каком этапе отправка товара (сборка, маршрут, получение товара).</p>
        <p>4.6. Доставленный товар передается Покупателю по указанному им адресу, а при отсутствии Покупателя - любому лицу, предъявившему информацию о номере заказа, либо иное (в том числе электронное) подтверждение заключения договора розничной купли-продажи или оформление заказа.</p>
        <p>4.7. В случае если доставка товара произведена в установленные сроки, но товар не был передан Покупателю по его вине, последующая доставка производится в новые сроки, согласованные с Продавцом, на условиях, предусмотренных договором розничной купли-продажи.</p>
        <p>4.8. Риск случайной гибели или повреждения товара переходит к Покупателю с момента фактической передачи ему заказа.</p>
        <p>4.9. Обязанность Продавца по доставке заказа считается исполненной в полном объеме с момента передачи товара перевозчику, курьеру, службе доставки.</p>
        <h2>5. Цена товара и порядок оплаты</h2>

        <p>5.1. Наличие товаров, представленных на сайте Продавца, определяется индивидуальным статусом товара, отображаемым в каталоге товаров, а также в карточке товара с его подробным описанием. Товар сопровождается фотоизображениями, являющимися собственностью Продавца. Информация о изображении и стоимости товара, его наличии, размерах, цветах, составе указана на сайте.</p>
        <p>5.2. Товар может иметь незначительные отличия от изображения в цвете, форме, размере или других параметрах.</p>
        <p>5.3. Продажа товара в соответствии с условиями настоящей публичной оферты осуществляется в режиме реального времени (круглосуточно). Продавец вправе в любое время изменять стоимость товара и информацию о его наличии по своему усмотрению. После оформления заказа стоимость товаров в заказе не изменяется.</p>
        <p>5.4. Продавец может устанавливать скидки и систему бонусов, информация о которых размещается на сайте. Скидки могут быть изменены Продавцом в одностороннем порядке. Расчеты между Покупателем и Продавцом осуществляются банковской картой при оформлении заказа.</p>

        <h2>6. Порядок возврата товара и денежных средств</h2>

        <p>6.1. В соответствии с Постановлением Правительства РФ от 27 сентября 2007 г. N 612 "Об утверждении Правил продажи товаров дистанционным способом", Покупатель вправе отказаться от товара до момента его получения.</p>
        <p>6.1.1. При отказе от заказа в момент доставки Покупателем оплачиваются расходы Продавца на доставку в размере, указанном в накладной.</p>
        <p>6.1.2. Возврат товара осуществляется в соответствии с Правилами продажи товаров дистанционным способом. Возврат товара производится Покупателем отправкой почтового отправления на адрес Продавца.</p>
        <p>6.1.3. Возврат товара ненадлежащего качества возможен после уведомления Продавца и отправки товара с дефектами.</p>
        <p>6.2. Возврат товара надлежащего качества:</p>
        <p>6.2.1. Покупатель вправе отказаться от товара в течение 7 дней после его получения.</p>
        <p>6.2.2. Возврат товара возможен при сохраненном товарном виде, потребительских свойствах и не поврежденной упаковке.</p>
        <p>6.2.3. Расходы по возврату товара надлежащего качества несет Покупатель.</p>
        <p>6.2.4. Продавец обязан вернуть сумму, уплаченную Покупателем, не позднее 10 дней с момента предъявления требования, при условии поступления товара обратно на склад Продавца.</p>
        <p>6.3. Возврат товара ненадлежащего качества:</p>
        <p>6.3.1. Покупатель может предъявить требования по товару с дефектами в течение 14 дней с момента получения.</p>
        <p>6.3.2. Возврат товара ненадлежащего качества осуществляется после уведомления Продавца и отправки товара с дефектами.</p>
        <p>6.3.3. Обратная пересылка товара с дефектами осуществляется за счет Покупателя. Продавец возмещает расходы после подтверждения дефектов.</p>
        <p>6.3.4. Продавец обязуется вернуть сумму, уплаченную Покупателем, не позднее 10 дней с момента предъявления требования, при условии поступления товара обратно на склад Продавца.</p>

        <h2>7. Ответственность</h2>

        <p>7.1. Покупатель несет ответственность за содержание и достоверность предоставленных данных при заключении Договора. Продавец освобождается от ответственности за нарушение условий доставки в случае предоставления недостоверных данных.</p>
        <p>7.2. Продавец не несет ответственности за ненадлежащее использование товаров Покупателем.</p>
        <p>7.3. Продавец имеет право отказать в заключении Договора в случае систематического отказа Покупателя от получения заказанных товаров.</p>

        <h2>8. Порядок рассмотрения споров и претензий</h2>

        <p>8.1. Претензии Покупателя принимаются Продавцом к рассмотрению по электронной почте info@kanturstore.ru в течение 7 календарных дней с момента возникновения спорной ситуации.</p>
        <p>8.2. Споры разрешаются путем переговоров. В случае недостижения соглашения спор передается в суд в соответствии с законодательством Российской Федерации.</p>

        <h2>9. Срок действия публичной оферты</h2>

        <p>Настоящая оферта вступает в силу с момента ее акцепта Покупателем и действует до момента отзыва акцепта или фактического исполнения, в зависимости от того, что наступит раньше.</p>

        <h2>10. Дополнительные условия</h2>

        <p>10.1. Продавец вправе передать свои права и обязанности третьим лицам.</p>
        <p>10.2. Продавец может проводить профилактические и иные работы, временно ограничив доступ к сайту.</p>

      </div>
    </div>
  )
}
