import React from 'react'
import './PrivacyPolicy.css'
export default function PrivacyPolicy() {
  return (
    <div className='privacy_policy'>
      Политика интернет-магазинав отношении обработки персональных данных
      (Политика конфиденциальности)

      <h2>1. Общие положения</h2>

      <p>1.1. Политика «Kantur Store» (наименование интернет-магазина), расположенного на доменном имени kantur.ru, (далее - интернет-магазин, оператор персональных данных, оператор) в отношении обработки персональных данных (далее - политика) разработана в целях обеспечения защиты прав и свобод субъекта персональных данных при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>

      <p>1.2. Основные понятия, используемые в настоящей политике:</p>

      <ul>
        <li>персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);</li>
        <li>обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</li>
        <li>автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники;</li>
        <li>распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц;</li>
        <li>предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</li>
        <li>блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</li>
        <li>уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;</li>
        <li>обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</li>
        <li>администрация сайта интернет-магазина - уполномоченные сотрудники на управление сайтом, действующие от интернет-магазина, которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</li>
        <li>пользователь сайта интернет-магазина (далее - пользователь) - лицо, имеющее доступ к сайту посредством сети Интернет и использующее сайт интернет-магазина.</li>
      </ul>

      <p>1.3. Оператор, получивший доступ к персональным данным, обязан соблюдать конфиденциальность персональных данных - не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.</p>

      <p>1.4. Субъект персональных данных имеет право на получение информации, касающейся обработки его персональных данных, в том числе содержащей:</p>

      <ul>
        <li>подтверждение факта обработки персональных данных оператором;</li>
        <li>правовые основания и цели обработки персональных данных;</li>
        <li>цели и применяемые оператором способы обработки персональных данных;</li>
        <li>наименование и место нахождения оператора, сведения о лицах (за исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с оператором или на основании федерального закона;</li>
        <li>обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен федеральным законом;</li>
        <li>сроки обработки персональных данных, в том числе сроки их хранения;</li>
        <li>порядок осуществления субъектом персональных данных прав, предусмотренных Федеральным законом от 27 июля 2006 г. N 152-ФЗ "О персональных данных" (далее - Закон о персональных данных);</li>
        <li>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</li>
        <li>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению оператора, если обработка поручена или будет поручена такому лицу;</li>
        <li>информацию о способах исполнения оператором обязанностей, установленных статьей 18.1 Закона о персональных данных;</li>
        <li>иные сведения, предусмотренные Законом о персональных данных или другими федеральными законами.</li>
      </ul>

      <p>1.5. Субъект персональных данных вправе требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>

      <p>1.6. Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.</p>

      <p>1.7. Оператор персональных данных вправе:</p>

      <ul>
        <li>отстаивать свои интересы в суде;</li>
        <li>предоставлять персональные данные субъектов персональных данных третьим лицам, если это предусмотрено действующим законодательством (налоговые, правоохранительные органы и др.);</li>
        <li>отказывать в предоставлении персональных данных в случаях, предусмотренных законодательством;</li>
        <li>использовать персональные данные субъекта персональных данных без его согласия в случаях, предусмотренных законодательством.</li>
      </ul>

      <p>1.8. При сборе персональных данных оператор обязан предоставить субъекту персональных данных по его просьбе информацию, предусмотренную частью 7 статьи 14 Закона о персональных данных.</p>

      <p>1.9. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети "Интернет", оператор обязан обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в пунктах 2, 3, 4, 8 части 1 статьи 6 Закона о персональных данных.</p>

      <h2>2. Цели сбора персональных данных</h2>

      <p>2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>

      <p>2.2. Цели обработки персональных данных происходят в том числе из анализа правовых актов, регламентирующих деятельность оператора, целей фактически осуществляемой оператором деятельности, а также деятельности, которая предусмотрена учредительными документами оператора, и конкретных бизнес-процессов оператора в конкретных информационных системах персональных данных (по структурным подразделениям оператора и их процедурам в отношении определенных категорий субъектов персональных данных).</p>

      <p>2.3. К целям обработки персональных данных оператора относятся:</p>

      <ul>
        <li>идентификация пользователя, зарегистрированного на сайте интернет-магазина, для оформления заказа и (или) заключения договора купли-продажи товара дистанционным способом с интернет-магазином;</li>
        <li>предоставление пользователю доступа к персонализированным ресурсам сайта интернет-магазина;</li>
        <li>установление с пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта интернет-магазина, оказания услуг, обработка запросов и заявок от пользователя;</li>
        <li>определение места нахождения пользователя для обеспечения безопасности, предотвращения мошенничества;</li>
        <li>подтверждение достоверности и полноты персональных данных, предоставленных пользователем;</li>
        <li>создание учетной записи для совершения покупок, если пользователь дал согласие на создание учетной записи;</li>
        <li>уведомление пользователя сайта интернет-магазина о состоянии заказа;</li>
        <li>предоставление пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта интернет-магазина;</li>
        <li>предоставление пользователю с его согласия обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени интернет-магазина или от имени партнеров интернет-магазина;</li>
        <li>осуществление рекламной деятельности с согласия пользователя.</li>
      </ul>


      <h2>3. Правовые основания обработки персональных данных</h2>

      <p>3.1. Правовым основанием обработки персональных данных являются:</p>

      <ul>
        <li>совокупность правовых актов, во исполнение которых и в соответствии с которыми оператор осуществляет обработку персональных данных;</li>
        <li>уставные документы оператора;</li>
        <li>договоры, заключаемые между оператором и субъектом персональных данных;</li>
        <li>согласие на обработку персональных данных (в случаях, прямо не предусмотренных законодательством Российской Федерации, но соответствующих полномочиям оператора).</li>
      </ul>

      <h2>4. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных</h2>

      <p>4.1. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.</p>

      <p>4.2. Обработка персональных данных допускается в следующих случаях:</p>

      <ul>
        <li>обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</li>
        <li>обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем. Заключаемый с субъектом персональных данных договор не может содержать положения, ограничивающие права и свободы субъекта персональных данных, устанавливающие случаи обработки персональных данных несовершеннолетних, если иное не предусмотрено законодательством Российской Федерации, а также положения, допускающие в качестве условия заключения договора бездействие субъекта персональных данных;</li>
      </ul>

      <p>4.3. К категориям субъектов персональных данных относятся пользователи сайта интернет-магазина. В данной категории субъектов оператором обрабатываются персональные данные:</p>

      <ul>
        <li>в целях идентификации пользователя для оформления заказа и/или заключения договора купли-продажи товара дистанционным способом;</li>
        <li>предоставления пользователю доступа к персонализированным ресурсам сайта интернет-магазина;</li>
        <li>установления с пользователем обратной связи, включая направление рекламных рассылок, уведомлений, запросов, касающихся использования сайта интернет-магазина, оказания услуг, обработки запросов и заявок от пользователя;</li>
        <li>определения места нахождения пользователя для обеспечения безопасности, предотвращения мошенничества;</li>
        <li>подтверждения достоверности и полноты персональных данных пользователя;</li>
        <li>создания учетной записи для заказа товара;</li>
        <li>уведомления пользователя сайта интернет-магазина о состоянии заказа;</li>
        <li>обработки и получения платежей, оспаривания платежа, полученного от пользователя;</li>
        <li>предоставления пользователю с его согласия обновлений товаров, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени интернет-магазина или от имени партнеров интернет-магазина.</li>
      </ul>

      <p>4.3.1. Персональные данные, разрешённые к обработке в рамках настоящей Политики, предоставляются пользователем путём заполнения заявки на сайте интернет-магазина и включают в себя следующую информацию:</p>

      <ul>
        <li>фамилию, имя, отчество пользователя;</li>
        <li>контактный телефон пользователя;</li>
        <li>адрес электронной почты (e-mail);</li>
        <li>адрес доставки и/или место жительства пользователя для доставки товара;</li>
        <li>информацию о банковских картах (Ф. И. О., N карты).</li>
      </ul>

      <p>4.4. Оператор защищает данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы:</p>

      <ul>
        <li>IP-адрес;</li>
        <li>информация из cookies;</li>
        <li>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
        <li>время доступа;</li>
        <li>адрес страницы, на которой расположен рекламный блок;</li>
        <li>реферер (адрес предыдущей страницы).</li>
      </ul>
      

          </div>
  )
      }
