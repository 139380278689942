import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./CreateOrder.css"
import { AxiosAuthGet, AxiosAuthPost } from "../../services/AxiosRequests";
import PochtaModal from "../PochtaModal/PochtaModal";
import CdekModal from "../CdekModal/CdekModal";
import { isMobile } from 'react-device-detect';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import CartStore from "../../Stores/Cart";
import { checkAuthentication } from "../../services/AuthService";

function CreateOrder() {
	const [preorder, setPreorder] = useState(null);
	const [ActiveDelivery, setActiveDelivery] = useState();
	const [ActivePayType, setActivePayType] = useState();
	const [isPochtaModalOpen, setIsPochtaModalOpen] = useState(false);
	const [isCdekModalOpen, setIsCdekModalOpen] = useState(false);
	const [isFullName, setIsFullName] = useState(false);
	const [isDeliveryPicked, SetIsDeliveryPicked] = useState(false);
	const [isTypePayPicked, SetIsTypePayPicked] = useState(false);
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [deliveryPrice, setdeliveryPrice] = useState(0);
	const [loading, setLoading] = useState(true);
	const [TextPromocode, setTextPromocode] = useState('');
	const [Promocode, setPromocode] = useState(null);
	const [errorEmail,setErrorEmail] = useState(false);
	const [validEmail,setValidEmail] = useState(false);


	async function OpenBoxberryModal() {
		const script = document.createElement('script');
		script.type = "text/javascript"
		script.src = 'https://points.boxberry.ru/js/boxberry.js';
		script.onerror = () => {
			window.boxberry.close()
			window.boxberry.open(ChooseBoxberryDeliveryFunction, '1$41cc9a48f55e198a6f34d20cad716507', ' Москва', '74571', '2000', '2000', '0', '32', '20', '12')
		}
		script.onload = async () => {
			try {
				await window.boxberry.open(ChooseBoxberryDeliveryFunction, '1$41cc9a48f55e198a6f34d20cad716507', ' Москва', '74571', '2000', '2000', '0', '32', '20', '12')
			} catch {

			}
		}
		document.getElementById('boxberry-widget').appendChild(script);
		return () => {
			document.getElementById('boxberry-widget').removeChild(script);
		};
	};

	async function ChooseBoxberryDeliveryFunction(data) {
		try {
			const respons_second = await AxiosAuthPost('/Order/ChangePreorderData', { "delivery_type": 'boxberry', "pvz": data.id })
			if (respons_second.status === 200) {
				SetIsDeliveryPicked(true);
				setActiveDelivery('boxberry');
				setPreorder(respons_second.data);
				setdeliveryPrice(respons_second.data.price)
			}
		}
		catch (error) {
			console.error(error)
		}
	}

	async function SetPayType(type) {
		try {
			const response = await AxiosAuthPost('/Order/ChangePreorderData', { "pay_type": type, })
			if (response.status === 200) {
				setActivePayType(type);
				SetIsTypePayPicked(true)
			}
		}
		catch (error) {
			console.error(error)
		}
	}

	async function Pay() {
		try {
			const response = await AxiosAuthGet('/Order/CreatePayUrl')
			if (response.status === 200) {
				window.location.replace(response.data.url);
			}
		}
		catch (error) {
			console.error(error)
		}
	};

	async function sendRequest(data) {
		try {
			const response = await AxiosAuthPost('/Order/ChangePreorderData', data)
			if (response.data){
			}
		}
		catch (error) {
		}
		checkName();
	};

	async function sendEmail(data) {
		try {
			const response = await AxiosAuthPost('/Order/ChangePreorderData', data)
			if (response.status===200){
				setErrorEmail(false);
				setValidEmail(false);
			}
		}
		catch (error) {
			if(error.response.data==="EMAIL_NOT_UNIQUE"){
				setErrorEmail(true);
				setValidEmail(false);
			}
			if(error.response.data==="EMAIL_NOT_VALID"){
				setValidEmail(true);
				setErrorEmail(false);
			}
		}
		checkName();
	};

	async function SetPromocode() {
		try {
			const response = await AxiosAuthPost('/Order/ChangePreorderData', { "promocode": TextPromocode, })
			setPreorder(response.data);
			setPromocode(response.data.promocode)
		}
		catch (error) {
			if (error.response.data === 'END_TIME_PROMOCODE') {
				alert('Промокод не действителен')
			}
			if (error.response.data === 'PROMOCODE_NOT_EXIST') {
				alert('Промокод не существует')
			}
			if (error.response.data === 'END_COUNT_USES_PROMOCODE') {
				alert('Кол-во использований промокода исчерпано')
			}
		}

	}

	useEffect(() => {
		const delay = 1000; // Задержка в миллисекундах
		const timeoutId = setTimeout(() => {
			sendRequest({ "name": name });
			checkName();
		}, delay);
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line 
	}, [name]);

	useEffect(() => {
		const delay = 1000; // Задержка в миллисекундах
		const timeoutId = setTimeout(() => {
			sendRequest({ "surname": surname }); 
			checkName();
		}, delay);
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line 
	}, [surname]);

	useEffect(() => {
		const delay = 1000; // Задержка в миллисекундах
		const timeoutId = setTimeout(() => {
			sendEmail({ "email": email }); // Отправить запрос после задержки
		}, delay);

		// Очистить таймаут при каждом новом вводе текста
		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line 
	}, [email]);

	useEffect(() => {
		async function fetchdata() {

			try {
				const response = await AxiosAuthGet('/Order/PreorderView')
				checkName();
				setPreorder(response.data);
				setLoading(false);
				if (response.data.price === null) {
					setdeliveryPrice(0);
				}
				else {
					setdeliveryPrice(response.data.price);
				}
				setActiveDelivery(response.data.delivery_type);
				if (name === '') {

					setName(response.data.name)
				}
				if (surname === '') {

					setSurname(response.data.surname)
				}
				if (email === '') {

					setEmail(response.data.email)
				}
				if (response.data.promocode) {
					setPromocode(response.data.promocode)
					setTextPromocode(response.data.promocode.code)
				}
				if (response.data.delivery_type !== '' && response.data.delivery_type !== null) { SetIsDeliveryPicked(true) }
				if (response.data.pay_type !== '' && response.data.pay_type !== null) { setActivePayType(response.data.pay_type); SetIsTypePayPicked(true); }
				checkName();
			}
			catch (error) {
				console.error(error)
			}
			try {
				const response = await AxiosAuthGet('/Users/ProfileDataView')
				if (response.status === 200) {
					if (response.data.name) {
						setName(response.data.name);
					}
					if (response.data.surname) {
						setSurname(response.data.surname);
					}
					if (response.data.email) {
						setEmail(response.data.email);
					}
					if (response.data.email !== null && response.data.email !== '') { setEmail(response.data.email) }
				}
				checkName();
			}
			
			catch (error) {
				console.error(error)
			}
		};
		fetchdata();
		// eslint-disable-next-line 
	}, []);


	useEffect(()=>{
		async function fetchdata(){
			const isAuth = checkAuthentication();
			if (!isAuth){
				window.location.replace('/');
			}
		}
		fetchdata();
		checkName();
		// eslint-disable-next-line 
	},[])

	useEffect(() => {
		async function fetchdata() {
			if (CartStore.total_quantity !== 0 && preorder !== null && preorder.delivery_type !== null) {
				const response = await AxiosAuthGet('/Order/ChangePreorderPrice');

				if (response.status === 200) {
					if (response.data.price === null) {
						setdeliveryPrice(0);
					}
					else {
						setdeliveryPrice(response.data.price);
						setPreorder(response.data);
					}
					setLoading(false);
					checkName();
				}
			}
			else {
				setdeliveryPrice(0);
			}
		};
		fetchdata();
		// eslint-disable-next-line 
	}, [CartStore.total_quantity]);
	
	function checkName(){
		if ((name !== null && name.length>0) && (surname !== null && surname.length>0)) {
			setIsFullName(true)
		}
		else {
			setIsFullName(false)
		}
	}
	
	useEffect(() => {
		checkName();
		// eslint-disable-next-line 
	}, [name, surname]);

	return (
		!loading ?
			(
				<>
					<div className="text_order">
						<h1>Корзина / Оформление заказа</h1>
					</div>
					<div className="order">
						<div className="questionnaire">

							<div className="fio_part">
								<div className="fio_part_lable">
									<p className="fio_label">1. ФИО</p>
								</div>
								<div className="fio_part_inputs">
									<input type="text" className="fio1" id="surname" placeholder="Введите фамилию" value={surname} onChange={(e) => { setSurname(e.target.value)}} />
									<input type="text" className="fio1" id="name" placeholder="Введите имя" value={name} onChange={(e) => { setName(e.target.value); }} />
								</div>
								{isFullName ?
									(<p>Верные данные</p>)
									:
									(<p className="info_fio">Данные необходимы для получения и оформления заказа</p>)
								}
							</div>

							<div className="email_part">
								<div className="email_part_lable">
									<p className="fio_label">2. Почта</p>
								</div>
								<div className="email_part_input">
									<input type="text" className="fio1" id="email" placeholder="Введите почту" value={email} onChange={(e) => { setEmail(e.target.value)}} />
									{errorEmail ?
									(<h4>Введите уникальную почту</h4>)
									:
									(<></>)
									}
									{validEmail ?
									(<h4>Почта не корректна</h4>)
									:
									(<></>)
									}
									<p className="info_fio">Данные необходимы для получения и оформления заказа</p>
								</div>
							</div>

							<div className="delivery_part">
								<div className="delivery_part_lable">
									<p className="fio_label">3. Способы доставки</p>
								</div>
								<div className="delivery_part_buttons">
									{/* <button onClick={() => { setIsPochtaModalOpen(true); }} className={`pmail_and_courierost ${ActiveDelivery === 'pochta' ? 'pmail_and_courierost_active' : 'pmail_and_courierost_normal'}`} disabled={!CartStore.total_count > 0} >Почта России</button> */}
									<button onClick={() => { OpenBoxberryModal(); }} className={`pmail_and_courierost ${ActiveDelivery === 'boxberry' ? 'pmail_and_courierost_active' : 'pmail_and_courierost_normal'}`} disabled={!CartStore.total_count > 0} >Boxberry</button>
									<button onClick={() => { setIsCdekModalOpen(true); }} className={`pmail_and_courierost ${ActiveDelivery === 'cdek' ? 'pmail_and_courierost_active' : 'pmail_and_courierost_normal'}`} disabled={!CartStore.total_count > 0} >Cdek</button>
								</div>
								{isDeliveryPicked ?
									(<div>
										<p><span>Адресс пункта выдачи</span> - {preorder.address}</p>
										<p><span>Ориентировочное время доставки</span> - {preorder.delivery_days} {preorder.delivery_days === 1 ? 'день' : preorder.delivery_days >= 2 && preorder.delivery_days <= 4 ? 'дня' : 'дней'}</p>
									</div>)
									:
									(<p className="info_fio">Выберете способ доставки</p>)
								}
							</div>

							<div className="pay_part">
								<div className="pay_part_lable">
									<p className="fio_label">4. Способ оплаты</p>
								</div>
								<div className="pay_part_buttons">
									<button className={`payment ${ActivePayType === 'bank_card' ? 'payment_active' : 'payment_normal'}`} id="payment" onClick={() => { SetPayType('bank_card') }}>
										<p className="elem_pay" id="elem_pay">Банковская карта</p>
										{isMobile ? (<><div className="sber_container"><img src="static/mir_pay.png" id="image2" alt="mir_pay"></img><img src="static/Group.svg" id="image1" alt="group"></img></div></>) : (<img src="static/Group.svg" id="image1" alt="group"></img>)}
									</button>
									{/* <button className={`payment ${ActivePayType === 'sberbank' ? 'payment_active' : 'payment_normal'}`} id="payment2" onClick={() => { SetPayType('sberbank') }}>
										<p className="elem_pay" id="elem_pay2">SberPay</p>
										<img src="static/sber.svg" alt="sber"></img>
									</button>
									<button className={`payment ${ActivePayType === 'sbp' ? 'payment_active' : 'payment_normal'}`} id="payment3" onClick={() => { SetPayType('sbp') }}>
										<p className="elem_pay" id="elem_pay3">СБП</p>
										<img src="static/sbp.png" alt="sbp"></img>
									</button> */}

								</div>
							</div>
							{isTypePayPicked ?
								(<></>)
								:
								(<p className="info_fio">Выберете способ оплаты</p>)
							}
							<div className="order_cart_price_mobile">
								<p className="promo">Промокод</p>
								<div className="promo_place">
									<input type="text" placeholder="Введите промокод" className="promo_input" value={TextPromocode} onChange={(e) => { setTextPromocode(e.target.value); }} />
									<button className="promo_btn" onClick={() => { SetPromocode() }}>Применить</button>
								</div>
								{loading ? (<LoadingAnimation></LoadingAnimation>) :
									(
										<>
											{!Promocode ?
												(
													<>
														<div className="sum_place">
															<p className="sum">Сумма</p>
															<p className="sum">{preorder.item_total_price} ₽</p>
														</div>
														<div className="sum_place">
															<p className="sum">Доставка</p>
															<p className="sum">{parseFloat(deliveryPrice)} ₽</p>
														</div>
														<div className="itog_place">
															<p className="itog">Итог</p>
															<p className="itog">{parseFloat(preorder.item_total_price) + parseFloat(deliveryPrice)} ₽</p>
														</div>
													</>
												) :
												(
													<>
														<div className="sum_place">
															<p className="sum">Сумма</p>
															<p className="sum promocode">{CartStore.total_price} ₽</p>
														</div>
														<div className="sum_place">

															{Promocode.is_percent ?
																<>
																	<p className="sum">Сумма со скидкой {preorder.promocode.discount_percent}%</p>
																	<p className="sum">{preorder.item_total_price} ₽</p>
																</>
																:
																<>
																	<p className="sum">Сумма со скидкой {preorder.promocode.discount_value + ' ₽'}</p>
																	<p className="sum">{CartStore.total_price - Promocode.discount_value} ₽</p>
																</>
															}

														</div>
														<div className="sum_place">
															<p className="sum">Доставка</p>
															<p className="sum">{parseFloat(deliveryPrice)} ₽</p>
														</div>
														<div className="itog_place">
															<p className="itog">Итог</p>
															{Promocode.is_percent ?
																<p className="itog">{parseFloat(preorder.item_total_price) + parseFloat(deliveryPrice)} ₽</p>
																:
																<p className="itog">{parseFloat(preorder.item_total_price) + parseFloat(deliveryPrice)} ₽</p>
															}


														</div>
													</>
												)}

										</>
									)}
							</div>
							<button className={(!validEmail && !errorEmail && isFullName && isDeliveryPicked && isTypePayPicked) ? ('button_confirmation_enabled1') : ('button_confirmation1')} onClick={() => Pay()} disabled={(!isFullName || !isDeliveryPicked || !isTypePayPicked || validEmail || errorEmail)}>Перейти к оплате</button>
						</div>

						{CartStore.total_count > 0 ? (
							<div className="questionnaire_right">
								<div className="order_cart_items">
									{CartStore.cart.map((item, index) => (
										<div className="help_que" key={index}>

											<img src={process.env.REACT_APP_IMG_URL + item.img} alt={item.name} className="img_right" />
											<div className="elements">
												<div className="first_elem">
													<div className="info_right">
														<p className="info_right_first2">{item.name}</p>
														<p className="info_right_second">{item.gender}</p>
													</div>
													<div className="info_right2">
														{!item.price.discount_flag ?
															(<p className="info_right_fouth_2">{item.price.end_price} ₽</p>)
															:
															(<>
																<p className="info_right_third">{item.price.discount_price} ₽</p>
																<p className="info_right_fouth">{item.price.end_price} ₽</p>
															</>)
														}

													</div>
												</div>
												<div className='cart_popup_item_subline'>
													<center>{item.count === 2 ? (<p className='cart_popup_item_center_out_of_2'>Это максимальное количество доступное для заказа</p>) : (<p>.</p>)}</center>
												</div>
												<div className="size">
													<button onClick={() => { CartStore.ChangeItemCount(item, 'minus') }} className="minus" ><img src="static/minus.svg" className="minus_img" alt="minus_img" /></button>
													<p className="number">{item.count}</p>
													{item.count < 2 && CartStore.total_quantity < 5 ? (
														<button onClick={() => CartStore.ChangeItemCount(item, 'plus')} className="plus" ><img src="static/plusorder.svg" className="plus_img" alt="plus_img" /><></></button>
													) : (
														<button className="plus" disabled></button>
													)}
													<p className="text_size">{item.size} размер</p>
												</div>
											</div>
										</div>
									))}
								</div>

								<div className="order_cart_price">
									<p className="promo">Промокод</p>
									<div className="promo_place">
										<input type="text" placeholder="Введите промокод" className="promo_input" value={TextPromocode} onChange={(e) => { setTextPromocode(e.target.value); }} />
										<button className="promo_btn" onClick={() => { SetPromocode() }}>Применить</button>
									</div>
									{loading ? (<LoadingAnimation></LoadingAnimation>) :
										(
											<>
												{!Promocode ?
													(
														<>
															<div className="sum_place">
																<p className="sum">Сумма</p>
																<p className="sum">{preorder.item_total_price} ₽</p>
															</div>
															<div className="sum_place">
																<p className="sum">Доставка</p>
																<p className="sum">{parseFloat(deliveryPrice)} ₽</p>
															</div>
															<div className="itog_place">
																<p className="itog">Итог</p>
																<p className="itog">{parseFloat(preorder.item_total_price) + parseFloat(deliveryPrice)} ₽</p>
															</div>
														</>
													) :
													(
														<>
															<div className="sum_place">
																<p className="sum">Сумма</p>
																<p className="sum promocode">{CartStore.total_price} ₽</p>
															</div>
															<div className="sum_place">

																{Promocode.is_percent ?
																	<>
																		<p className="sum">Сумма со скидкой {preorder.promocode.discount_percent}%</p>
																		<p className="sum">{preorder.item_total_price} ₽</p>
																	</>
																	:
																	<>
																		<p className="sum">Сумма со скидкой {preorder.promocode.discount_value + ' ₽'}</p>
																		<p className="sum">{CartStore.total_price - Promocode.discount_value} ₽</p>
																	</>
																}

															</div>
															<div className="sum_place">
																<p className="sum">Доставка</p>
																<p className="sum">{parseFloat(deliveryPrice)} ₽</p>
															</div>
															<div className="itog_place">
																<p className="itog">Итог</p>
																{Promocode.is_percent ?
																	<p className="itog">{parseFloat(preorder.item_total_price) + parseFloat(deliveryPrice)} ₽</p>
																	:
																	<p className="itog">{parseFloat(preorder.item_total_price) + parseFloat(deliveryPrice)} ₽</p>
																}


															</div>
														</>
													)}

											</>
										)}
								</div>



							</div>


						) : (
							<p>Корзина пуста</p>
						)}

					</div>
					{isCdekModalOpen ? (<CdekModal active={isCdekModalOpen} setActive={setIsCdekModalOpen} setpvz={setActiveDelivery} SetIsDeliveryPicked={SetIsDeliveryPicked} setdeliveryPrice={setdeliveryPrice} setPreorder={setPreorder} />) : (<></>)}
					{isPochtaModalOpen ? (<PochtaModal active={isPochtaModalOpen} setActive={setIsPochtaModalOpen} setpvz={setActiveDelivery} SetIsDeliveryPicked={SetIsDeliveryPicked} setPreorder={setPreorder} setdeliveryPrice={setdeliveryPrice} />) : (<></>)}

					<div id="boxberry-widget"></div>
				</>
			) : (
				<LoadingAnimation></LoadingAnimation>
			)
	)
}
export default observer(CreateOrder)