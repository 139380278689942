import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { AxiosGet } from '../../../../services/AxiosRequests';
import ProductItem from '../../../ProductItem/ProductItem';
import LoadingAnimation from '../../../LoadingAnimation/LoadingAnimation';
import './Search.css'
export default function Search({ isActive, setIsActive }) {
	const navigate = useNavigate();
	const [searchText, setSearchText] = useState('');
	const [products, setProducts] = useState([]);
	const search = (e) => {
		if (e.key === 'Enter') {
			handleSearch()
		}
	};

	const handleSearch = () => {
		navigate('/Search/' + searchText);
		setIsActive(false);
	};

	async function sendRequest(data) {
		const response = await AxiosGet('/Store/Search_s/?search=' + searchText);
		// if (response.status===200){

		// }
		// else
		setProducts(response.data.slice(0,10));
	};

	useEffect(() => {
		const delay = 1000; // Задержка в миллисекундах

		const timeoutId = setTimeout(() => {
			if (searchText !== '') {
				sendRequest(searchText);
			}
			else {
				setProducts([]);
			}
		}, delay);


		// Очистить таймаут при каждом новом вводе текста
		return () => clearTimeout(timeoutId);
	}, [searchText]);

	return (
		<>
			<div className={!isActive ? 'search_popup' : 'search_popup active'} onClick={()=>{setIsActive(false)}}>
				<div className='search_popup_content' onClick={(e) => e.stopPropagation()}>
					<div className='search_input'>
						<input
							type='text'
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							onKeyDown={search} // Обработчик нажатия клавиши
						/>
						<div className='search_button'>
							<NavLink to={'/Search/' + searchText}>
								<button onClick={handleSearch} disabled={searchText.length <= 0}>
									Найти
								</button>
							</NavLink>
						</div>
					</div>
					<div className='search_result'>
						<div className='search_result_content'>
						{products.map((product, id) => (
							<div className='shoes_page_item'>
								<ProductItem item={product} key={id}  />
							</div>
						))}
						{products.length>0 ? <NavLink to={'/Search/' + searchText} onClick={()=>{setIsActive(false)}}>Посмотреть все</NavLink>:<></>}
						
						</div>
					</div>
				</div>

			</div>
		</>
	)
}
