import React from 'react'
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CartStore from '../../../../Stores/Cart';
import './MobileHeader.css'
import MobileMenu from './MobileMenu/MobileMenu';
import { observer } from 'mobx-react';
function MobileHeader({ isModalAuthOpen, setIsModalAuthOpen, isModalCartOpen, setIsModalCartOpen, isModalProfileOpen, setisModalProfileOpen, isAuthenticated, setIsAuthenticated, isModalSearchOpen, setIsModalSearchOpen, }) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isBrandPopupOpen, setIsBrandPopupOpen] = useState(false);
    const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false);
    const [isPopularPopupOpen, setIsPopularPopupOpen] = useState(false);
    const [isSalePopupOpen, setIsSalePopupOpen] = useState(false);

    return (
        <>
            <div className="mobile_header">
                <div className="mobile_header_lines">
                    <button className='mobile_header_lines_button' onClick={()=>{setIsMobileMenuOpen(!isMobileMenuOpen)}}>
                        <img src="/static/line_menu.svg" alt="line" />
                        <img src="/static/line_menu.svg" alt="line" />
                        <img src="/static/line_menu.svg" alt="line" />
                    </button>
                </div>

                <div className="mobile_header_logo">
                    <NavLink to="/" ><img src="/static/logo_v2.svg" alt="KantureStore" /></NavLink>
                </div>


                <div className="mobile_header_auth">
                    <button><img src="/static/search.svg" alt="search" onClick={() => { setIsModalSearchOpen(!isModalSearchOpen); }} /></button>

                    <button onClick={() => { setIsModalCartOpen(!isModalCartOpen) }}>                        
                    {CartStore.total_count > 0 ? (<div className="cart_count1">{CartStore.total_count}</div>) : (<></>)}
                    <img src="/static/korzina.svg" alt="korzina" />
                        
                    </button>

                    {isAuthenticated ? (
                        <button id="profile" onClick={() => { setisModalProfileOpen(!isModalProfileOpen) }}><img src="/static/profile.svg" alt="" /></button>
                    ) : (
                        <button id="profile" onClick={() => { setIsModalAuthOpen(!isModalAuthOpen) }}><img src="/static/profile.svg" alt="" /></button>
                    )}
                </div>


            </div>
            
            {window.innerWidth<769 ? <MobileMenu isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} /> : <></>}

        </>
    )
}

export default observer(MobileHeader)