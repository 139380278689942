import React from 'react';
import './PochtaModal.css';
import EcomWidget from './widget';
import {AxiosAuthPost } from '../../services/AxiosRequests';
const PochtaModal = ({ active, setActive, setpvz, SetIsDeliveryPicked, setPreorder, setdeliveryPrice}) => {
	async function MyCallbackFunction(data) {
		const response = await AxiosAuthPost('/Order/ChangePreorderData',{"delivery_type": 'pochta',"pvz": data.indexTo,})
		if (response.status===200){
			
			setActive(false)
			setpvz('pochta')
			SetIsDeliveryPicked(true)
			setPreorder(response.data)
			setdeliveryPrice(response.data.price)
		}
		
	}
	return (
		<>
			<div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
				<div className={active ? 'modal__content active' : 'modal__content'} onClick={(e) => e.stopPropagation()}>
				<button className='buttonauthclose' onClick={()=>{setActive(!active)}}><img src='/static/krest.svg' alt='krest'></img></button>
					<EcomWidget callbackFunction={MyCallbackFunction}></EcomWidget>
				</div>
			</div>
		</>
	);
};

export default PochtaModal;
