import React, { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import { AxiosGet } from '../../../services/AxiosRequests';
import { Link, animateScroll as scroll } from 'react-scroll';
import { NavLink, useParams } from 'react-router-dom';
import FilterDesktop from '../FilterDesktop/FilterDesktop';
import FilterMobile from '../FilterMobile/FilterMobile';
import ProductItem from '../../ProductItem/ProductItem';
function Buttons(params, CountPage, CurrentPage) {
    const buttons = [];
    const queryParams = params.split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');
        if (key && value) {
            // Если параметр уже существует в объекте, превратите его в массив
            if (acc[key]) {
                acc[key] = Array.isArray(acc[key]) ? [...acc[key], decodeURIComponent(value.replace(/\+/g, ' '))] : [acc[key], decodeURIComponent(value.replace(/\+/g, ' '))];
            } else {
                acc[key] = [decodeURIComponent(value.replace(/\+/g, ' '))];
            }
        }
        return acc;
    }, {});
    for (let i = 1; i <= CountPage; i++) {
        buttons.push(
            <div className={`paginations_size ${i.toString() === queryParams.page[0] ? 'selected' : ''}`}>
                <NavLink to={'/shoes/filter/' + params.replace(/&?page=\d+/g, '') + '&page=' + i}><button disabled={i.toString() === queryParams.page} >{i}</button></NavLink>
            </div>

        );
    }

    return buttons;
}


export default function FilterMain() {
    const [shoes1, setShoes1] = useState([]);
    const [isFilterOpen, SetisFilterOpen] = useState(false);
    const [CountPage, setCountPage] = useState();
    const [loading, setLoading] = useState(true);
    const { params } = useParams();
    const [isFilterMobileOpen, setIsFilterMobileOpen] = useState(false);
    const [queryParams, setQueryParams] = useState(
        params.split('&').reduce((acc, param) => {
            const [key, value] = param.split('=');
            if (key && value) {
                if (acc[key]) {
                    acc[key] = Array.isArray(acc[key]) ? [...acc[key], decodeURIComponent(value.replace(/\+/g, ' '))] : [acc[key], decodeURIComponent(value.replace(/\+/g, ' '))];
                } else {
                    acc[key] = [decodeURIComponent(value.replace(/\+/g, ' '))];
                }
            }
            return acc;
        }, {})
    )
    useEffect(() => {
        async function fetchData1() {
            setQueryParams(params.split('&').reduce((acc, param) => {
                const [key, value] = param.split('=');
                if (key && value) {
                    if (acc[key]) {
                        acc[key] = Array.isArray(acc[key]) ? [...acc[key], decodeURIComponent(value.replace(/\+/g, ' '))] : [acc[key], decodeURIComponent(value.replace(/\+/g, ' '))];
                    } else {
                        acc[key] = [decodeURIComponent(value.replace(/\+/g, ' '))];
                    }
                }
                return acc;
            }, {}));
            try {

                const response = await AxiosGet('/Store/Filter/?' + params);
                if (response.status === 200) {
                    console.log(response.data);
                    setShoes1(response.data.results);
                    setCountPage(response.data.num_pages);
                    setLoading(false);

                }
            }
            catch (error) {
                console.error('Произошла ошибка:', error);
            }
        }
        scroll.scrollToTop({
            duration: 300, // You can adjust the duration as needed
            smooth: 'easeInOutQuad', // You can adjust the easing function
        });
        fetchData1();
    }, [params]);

    return (
        <>
            {loading ? (
                <LoadingAnimation>
                </LoadingAnimation>
            ) : (
                <>
                    <div className={window.innerWidth > 768 ? 'shoes_main' : 'shoes_main mobile'}  >
                        {window.innerWidth > 768 ?
                            <div className='filter_desktop'>
                                <FilterDesktop queryParams={queryParams} />
                            </div>
                            :
                            <div className='filter_mobile'>
                                <button className='filter_mobile_button' onClick={() => { setIsFilterMobileOpen(!isFilterMobileOpen) }}>
                                    <img src='/static/filter.svg' alt='' />
                                    <p>Фильтр</p>
                                </button>

                                <FilterMobile isActive={isFilterMobileOpen} setIsActive={setIsFilterMobileOpen} queryParams={queryParams} />
                            </div>
                        }
                        <div className='shoes_products'>
                            <div className='shoes_h2'></div>
                            <Link to="top" smooth={true} duration={1000}></Link>
                            <div className='shoes_page_cont'>
                                <div className={window.innerWidth > 768 ? "shoes_page_items" : "shoes_page_items mobile	"}>
                                    {shoes1.map((item, index) => (
                                        <div className='shoes_page_item'>
                                            <ProductItem item={item} key={index} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='paginations_sizes'>
                                {Buttons(params, CountPage, "1")}
                            </div>
                        </div>
                        
                    </div>
                </>
            )}
        </>
    );
}