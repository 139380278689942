import React from 'react'
import './Delivery.css'

export default function Delivery() {
  return (
    <div className='delivery1'>

      <h1 className='delivery1_h1'>Доставка</h1>
      <p className='delivery_service'>Мы предлагаем удобные варианты доставки вашего заказа, 
        чтобы сделать ваше покупательское испытание максимально комфортным. 
        Ниже вы найдете информацию о наших партнерах по доставке - CDEK и Boxberry.</p>

      <h2>Служба доставки CDEK</h2>
      <p className='delivery_CDEK'>Мы сотрудничаем с CDEK - одной из ведущих служб доставки в России. 
        CDEK предоставляет широкий спектр услуг по доставке, 
        а также отслеживание вашего заказа в режиме реального времени.</p>

      <h3>Стоимость доставки</h3>
      <p>Стоимость доставки рассчитывается автоматически при оформлении заказа, 
        исходя из вашего местоположения. Мы стремимся предоставить нашим клиентам 
        максимально прозрачные и справедливые тарифы.</p>

      <h3>Способы оплаты доставки</h3>
      <p>Вы можете оплатить стоимость доставки вместе с заказом или выбрать оплату при получении. 
        Мы предоставляем различные варианты оплаты, чтобы соответствовать вашим предпочтениям.</p>

      <h2>Служба доставки Boxberry</h2>
      <p>Boxberry - еще один надежный партнер по доставке, 
        который предоставляет широкий выбор пунктов выдачи и гарантирует сохранность вашего 
        заказа во время транспортировки.</p>

      <h3>Рассчет стоимости</h3>
      <p>Точная стоимость доставки Boxberry будет автоматически добавлена к вашему заказу 
        в момент оформления. Мы предлагаем конкурентоспособные тарифы для вашего удобства.</p>

      <h3>Время доставки</h3>
      <p>Сроки доставки могут варьироваться в зависимости от вашего местоположения. 
        Boxberry стремится доставлять заказы в кратчайшие сроки, 
        чтобы вы могли наслаждаться вашими покупками как можно быстрее.</p>

      <h2>Отслеживание заказа</h2>
      <p>После оформления заказа вы получите уникальный номер отслеживания, 
        который позволит вам следить за статусом доставки через систему CDEK или Boxberry.
        Мы ценим ваше время и стараемся сделать процесс покупки максимально удобным для вас.</p>

      <p>Спасибо за то, что выбрали нас! Если у вас есть какие-либо вопросы 
        или требуется дополнительная информация, не стесняйтесь связаться с нашей службой поддержки.</p>


    </div>
  )
}
