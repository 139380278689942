import React from 'react';
import './CdekModal.css';
import { AxiosAuthPost } from '../../services/AxiosRequests';
import CdekMap from '../CdekMap/CdekMap';
const CdekModal = ({ active, setActive, SetIsDeliveryPicked, setpvz ,setdeliveryPrice,setPreorder}) => {
	async function MyCallbackFunction(data) {
		setActive(false);
		try{
			const preorder = await AxiosAuthPost('/Order/ChangePreorderData',{"delivery_type": 'cdek',"pvz": data})
			
			setpvz('cdek');
			SetIsDeliveryPicked(true);
			setPreorder(preorder.data);	
			setdeliveryPrice(preorder.data.price)
		}
		catch(error){
			console.log(error)
		}
	}		
	return (
		<>
			<div className={active ? 'modal_cdek active' : 'modal_cdek'} onClick={() => setActive(false)}>
				<div className={active ? 'modal__content_cdek active' : 'modal__content_cdek'} onClick={(e) => e.stopPropagation()}>
				<button className='buttonauthclose' onClick={()=>{setActive(!active)}}><img src='/static/krest.svg' alt='close'></img></button>
					<CdekMap CallbackFunction={MyCallbackFunction}></CdekMap>
				</div>
			</div>
		</>
	);
};

export default CdekModal;
