import React, { useState, useEffect } from 'react';
import { AxiosGet } from '../../../../../services/AxiosRequests';
import { NavLink } from 'react-router-dom';
import './MobileMenu.css';

function model_list(setIsBrandPopupOpen, models) {
    return models.map((model) => (<p><NavLink onClick={() => setIsBrandPopupOpen(false)} to={'/shoes/filter/model=' + model + '&page=1'} >{model}</NavLink></p>))
}

export default function MobileMenu({ isMobileMenuOpen, setIsMobileMenuOpen }) {
    const [isBrandOpen, setIsBrandOpen] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [categorys, setCategorys] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const models = await AxiosGet('/Store/models');
            setModels(models.data);
            const categorys = await AxiosGet('/Store/categorys');
            setCategorys(categorys.data.categorys);
        };
        fetchData();
    }, []);

    function toggleBrands() {
        setIsBrandOpen(!isBrandOpen);
        setIsCategoryOpen(false);
    }

    function toggleCategorys() {
        setIsBrandOpen(false);
        setIsCategoryOpen(!isCategoryOpen);
    }

    return (
        <div className={isMobileMenuOpen ? 'mobile_menu active' : 'mobile_menu'} onClick={() => { setIsMobileMenuOpen(false) }}>
            <div className='mobile_menu_content' onClick={(e) => e.stopPropagation()}>
                <div className='mobile_menu_content_header'>
                    <h2>Каталог</h2>
                    <button onClick={() => { setIsMobileMenuOpen(false) }} ><img src='/static/krest.svg' /></button>
                </div>

                <div className='mobile_menu_content_button' onClick={toggleBrands}>
                    <img src='/static/right_arrow_menu.svg' alt='' />
                    Бренды
                </div>

                <div className={isBrandOpen ? 'mobile_menu_content_button_brand active' : 'mobile_menu_content_button_brand'}>

                    {Object.keys(models).map((brand) => (
                        <>
                        <h3><NavLink onClick={() => setIsMobileMenuOpen(false)} to={'/shoes/filter/brand=' + brand + '&page=1'} className='mobile_menu_content_button_brand_a' >{brand}</NavLink></h3>
                        {model_list(setIsMobileMenuOpen, models[brand])}
                        </>
                    ))}


                </div>

                <div className='mobile_menu_content_button' onClick={toggleCategorys}>
                    <img src='/static/right_arrow_menu.svg' alt='' />
                    Категории
                </div>

                <div className={isCategoryOpen ? 'mobile_menu_content_button_category active' : 'mobile_menu_content_button_category'}>
                    <h3>Пол</h3>
                    <p><NavLink onClick={() => { setIsMobileMenuOpen(false) }} to={'/shoes/filter/gender=male&page=1'} >Мужская</NavLink></p>
                    <p><NavLink onClick={() => { setIsMobileMenuOpen(false) }} to={'/shoes/filter/gender=female&page=1'} >Женская</NavLink></p>
                    <p><NavLink onClick={() => { setIsMobileMenuOpen(false) }} to={'/shoes/filter/gender=unisex&page=1'} >Унисекс</NavLink></p>

                    <h3>Тип</h3>
                    {categorys.map((category, index) => (
                        <p key={index}><NavLink onClick={() => { setIsMobileMenuOpen(false) }} to={'/shoes/filter/category=' + category.en + '&page=1'} >{category.ru}</NavLink></p>
                    ))}
                </div>
                <div className='mobile_menu_content_button' >
                    <img src='/static/right_arrow_menu.svg' alt='' />
                    <NavLink to='/shoes/filter/sale=true&page=1' onClick={() => setIsMobileMenuOpen(false)} >Скидки</NavLink>
                </div>
                <div className='mobile_menu_content_button' >
                    <img src='/static/right_arrow_menu.svg' alt='' />
                    Популярное
                </div>
            </div>
        </div>
    );
}
