import React from 'react'
import './BrandsPopup.css'
import { useEffect, useState } from 'react'
import { AxiosGet } from '../../../../../../services/AxiosRequests'
import { NavLink } from 'react-router-dom';

function model_list(setIsBrandPopupOpen,models){
	return models.map((model) => (<p><NavLink onClick={() => setIsBrandPopupOpen(false)} to={'/shoes/filter/model=' + model + '&page=1'} >{model}</NavLink></p>))
}

export default function BrandsPopup({ isBrandPopupOpen, setIsBrandPopupOpen }) {
	const [brands, setBrands] = useState([]);
	const [models, setModels] = useState([]);
	useEffect(() => {
		async function fetchData() {
			const brands = await AxiosGet('/Store/brand')
			setBrands(brands.data.brands)
			const models = await AxiosGet('/Store/models')
			console.log(models.data)

			setModels(models.data)
		};
		fetchData();
	}, [])
	return (
		<>

			<div className={isBrandPopupOpen ? 'BrandsPopup active' : 'BrandsPopup'} onMouseLeave={() => { setIsBrandPopupOpen(false) }}>
					<div className='BrandsPopup_subbrands'>
						<div className='BrandsPopup_subbrands_list'>
							{Object.keys(models).map((model) => (
								<div className='BrandsPopup_subbrands_list_item'>
									<h2><NavLink onClick={() => setIsBrandPopupOpen(false)} to={'/shoes/filter/brand=' + model + '&page=1'} >{model}</NavLink></h2>
									{model_list(setIsBrandPopupOpen,models[model])}
								</div>
							))}
						</div>
					</div>
				
			</div>

		</>
	)
}
