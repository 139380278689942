import React from 'react';
import "./Prodpage.css"
function SizeButton({ active, onClick, size }) {
	return (
		<button
			className={`button1 ${active ? 'button-active' : 'button-normal'}`}
			onClick={onClick}
		>
			{size}
		</button>
	);
}

export default SizeButton;