import React, { useEffect } from 'react';
import './PochtaModal.css';
function EcomWidget({callbackFunction}) {
  useEffect(() => {
    // Создаем элемент скрипта
    const script = document.createElement('script');
    script.src = 'https://widget.pochta.ru/map/widget/widget.js';
    script.async = true;

    // Загружаем скрипт и выполняем его после загрузки
    script.onload = () => {
      window.ecomStartWidget({

        id: 45001,
        callbackFunction: callbackFunction,
        containerId: 'ecom-widget',
      });
    };

    // Добавляем скрипт в DOM
    document.getElementById('ecom-widget').appendChild(script);

    // Очищаем скрипт при размонтировании компонента
  }, []);

  return (
    <div id="ecom-widget" className='ecom-widget'>
    </div>
  );
}

export default EcomWidget;
