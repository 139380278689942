import { useState } from 'react';
import React from 'react';
import './AuthModal.css';
import { useRef } from 'react';
import { AxiosPost, AxiosAuthPost } from '../../services/AxiosRequests';
import CartStore from '../../Stores/Cart';
const AuthModal = ({ active=false, setActive }) => {
	const [phone, setPhone] = useState('+7');
	const [code, setCode] = useState(''); // Добавляем состояние для кода подтверждения
	const [isPhoneInputVisible, setIsPhoneInputVisible] = useState(true); // Состояние, чтобы управлять видимостью блока ввода номера
	const [isCodeInputVisible, setIsCodeInputVisible] = useState(false); // Состояние, чтобы управлять видимостью блока ввода кода
	const [PhoneError, setPhoneError] = useState('');
	const [CodeError, setCodeError] = useState('');
	const [Timer, setTimer] = useState(10);
	const [isResendAvailable, setIsResendAvailable] = useState(false);
	const inputRef = useRef(null);

	const handleInputClick = () => {
		if (inputRef.current) {
			inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length;
		}
	};

	const handlePhoneInputChange = (event) => {
		setPhone(restorePlusSeven(event.target.value));
	};

	const handleCodeInputChange = (event) => {
		setCode(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Backspace' && phone === '+7') {
			event.preventDefault();
		}
		if (event.key === 'Enter') {
			// Если нажата клавиша Enter, вызываем обработчик кнопки
			handleContinueClick();
		}
	};

	const handleKeyDown2 = (event) => {
		if (event.key === 'Enter') {
			// Если нажата клавиша Enter, вызываем обработчик кнопки
			handleConfirmClick();
		}
	};

	const restorePlusSeven = (value) => {
		if (!value || !value.startsWith('+7')) {
			return '+7' + value;
		}
		return value;
	};

	const handleContinueClick = async () => {
		if (phone.length === 12) {
			try {
				const response = await AxiosPost('/Users/RegisterLogin', { 'phone_number': phone })
				if (response.status === 200) {
					setIsPhoneInputVisible(false);
					setIsCodeInputVisible(true);
					setTimer(60);
					setIsResendAvailable(false);
					StartTimer();
				}
			}
			catch (error) {
				setPhoneError(error)
			}
		}
		else {
			setPhoneError('Некорректный номер телефона')
		}
	};

	function StartTimer() {
		const timer_s = setInterval(() => {
			setTimer(prevSeconds => {
				const newSeconds = prevSeconds > 0 ? prevSeconds - 1 : 0;

				if (newSeconds === 0) {
					setIsResendAvailable(true);
					clearInterval(timer_s);
				}

				return newSeconds;
			});
		}, 1000);
	}

	const handleConfirmClick = async () => {
		try {
			const response = await AxiosPost('/Users/GetCode', { 'phone_number': phone, 'code': code })
			if (response.status === 200) {
				localStorage.setItem('accessToken', response.data.access);
				localStorage.setItem('refreshToken', response.data.refresh);
				if (CartStore.cart.length > 0) {
					const resp = await AxiosAuthPost('/Cart/add_to_cart_after_auth', { cart: CartStore.cart });
					if (resp.status === 201) {
						localStorage.removeItem('cart');
					}

				}
				setTimeout(() => { window.location.replace(window.location.pathname) }, 100);

			}
		}
		catch (error) {
			setCodeError('Неверный код');
		}
	};

	return (
		<>
			<div className={active ? 'modalauth authactive' : 'modalauth'} onClick={() => setActive(false)}>
				<div className={active ? 'modalauth__content authactive' : 'modalauth__content'} onClick={(e) => e.stopPropagation()}>
					<div className='modalauth__content_top'>
						{!isPhoneInputVisible ? <div className='buttonauthback' ><button onClick={() => { setIsPhoneInputVisible(!isPhoneInputVisible); setIsCodeInputVisible(!isCodeInputVisible) }}><img src='/static/auth_back.svg'></img></button></div> : <></>}

						<div className='buttonauthclose'><button onClick={() => { setActive(!active) }}><img src='/static/krest.svg'></img></button></div>
					</div>
					<div className='modalauth__content_bottom'>
						<h2>Вход или регистрация</h2>
						{isPhoneInputVisible && (
							<div className="modal_auth_flex">
								<p>Введите номер телефона, мы отправим вам СМС с кодом подтверждения</p>
								<input
									ref={inputRef}
									type="tel"
									id="phone"
									name="phone"
									value={phone}
									onChange={handlePhoneInputChange}
									className="inpuauth"
									placeholder="Телефон"
									onKeyDown={handleKeyDown}
									onClick={handleInputClick}

								/>
								<p>Нажимая продолжить в соглащаетесь с политикой обработки персональных данных</p>
								<p>{PhoneError}</p>
								<button className="buttonauth" onClick={handleContinueClick}>
									Продолжить
								</button>
							</div>
						)}

						{isCodeInputVisible && (
							<div className="modal_auth_flex">
								<p>Введите код подтверждения, отправленный на ваш номер</p>
								<input
									ref={inputRef}
									type="text"
									id="code"
									name="code"
									value={code}
									onChange={handleCodeInputChange}
									className="inpuauth"
									placeholder="Код подтверждения"
									onKeyDown={handleKeyDown2}
									onClick={handleInputClick}

								/>
								<div className='resend_code'>
									<p>Повторная отправка кода доступна через - {!isResendAvailable ? <>{Timer}</> : <></>} {isResendAvailable ? <button className='resend_code_button' onClick={() => { handleContinueClick() }}><p>Отправить код</p></button> : <></>}</p>
									<p>{CodeError}</p>
								</div>
								<button className="buttonauth" onClick={handleConfirmClick}>
									Подтвердить
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default AuthModal;
