import React from 'react'
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CartStore from '../../../../Stores/Cart';
import './DesktopHeader.css'
import BrandsPopup from './popups/BrandsPopup/BrandsPopup';
import CategoryPopup from './popups/CategoryPopup/CategoryPopup';
import { observer } from 'mobx-react';

function DesktopHeader({ isModalAuthOpen, setIsModalAuthOpen, isModalCartOpen, setIsModalCartOpen, isModalProfileOpen, setisModalProfileOpen, isAuthenticated, setIsAuthenticated, isModalSearchOpen, setIsModalSearchOpen, }) {
    const [isBrandPopupOpen, setIsBrandPopupOpen] = useState(false);
    const [isCategoryPopupOpen, setIsCategoryPopupOpen] = useState(false);
    const [isPopularPopupOpen, setIsPopularPopupOpen] = useState(false);
    const [isSalePopupOpen, setIsSalePopupOpen] = useState(false);

    function Logout() {
        localStorage.clear();
        window.location.replace('/');
    }
    function openBrand(){
        setIsBrandPopupOpen(true);
        setIsCategoryPopupOpen(false);
        setIsPopularPopupOpen(false);
        setIsSalePopupOpen(false);
    }
    function openCategory(){
        setIsBrandPopupOpen(false);
        setIsCategoryPopupOpen(true);
        setIsPopularPopupOpen(false);
        setIsSalePopupOpen(false);
    }
    function openPopular(){
        setIsBrandPopupOpen(false);
        setIsCategoryPopupOpen(false);
        setIsPopularPopupOpen(true);
        setIsSalePopupOpen(false);
    }
    function openSale(){
        setIsBrandPopupOpen(false);
        setIsCategoryPopupOpen(false);
        setIsPopularPopupOpen(false);
        setIsSalePopupOpen(true);
    }
    function closeAll(){
        setIsBrandPopupOpen(false);
        setIsCategoryPopupOpen(false);
        setIsPopularPopupOpen(false);
        setIsSalePopupOpen(false);
    }
    return (
        <>
            <div className="main_cont" onMouseEnter={()=>{closeAll()}}>

                <div className="logo">
                    <NavLink to="/" ><img src="/static/logo.svg" alt="KantureStore" /></NavLink>
                </div>

                <div className="main_navigation" >
                    <div className={isBrandPopupOpen ? 'main_navigation_chapter active' : 'main_navigation_chapter'} onMouseEnter={() => { openBrand() }} >Бренды</div>
                    <div className={isCategoryPopupOpen ? 'main_navigation_chapter active' : 'main_navigation_chapter'} onMouseEnter={() => { openCategory() }} >Категории</div>
                    <div className={isPopularPopupOpen ? 'main_navigation_chapter active' : 'main_navigation_chapter'} onMouseEnter={() => { openPopular() }} onMouseLeave={() => { closeAll() }} >Популярное</div>
                    <div className={isSalePopupOpen ? 'main_navigation_chapter active' : 'main_navigation_chapter'} onMouseEnter={() => { openSale() }} onMouseLeave={() => { closeAll() }} ><NavLink to='/shoes/filter/sale=true&page=1' >Скидки</NavLink></div>
                </div>

                <div className="auth">
                    <button href="/" id="search"><img src="/static/search.svg" alt="search" onClick={() => { setIsModalSearchOpen(!isModalSearchOpen);closeAll(); }} /></button>

                    <button onClick={() => { setIsModalCartOpen(!isModalCartOpen) }}>
                        {CartStore.total_count > 0 ? (<div className="cart_count1">{CartStore.total_count}</div>) : (<></>)}
                        <img src="/static/korzina.svg" alt="korzina" />
                    </button>

                    {isAuthenticated ? (
                        <button id="profile" onClick={() => { setisModalProfileOpen(!isModalProfileOpen) }}><img src="/static/profile.svg" alt="profile" /></button>
                    ) : (
                        <button id="profile" onClick={() => { setIsModalAuthOpen(!isModalAuthOpen) }}><img src="/static/profile.svg" alt="profile" /></button>
                    )}
                </div>


            </div>
            <BrandsPopup isBrandPopupOpen={isBrandPopupOpen} setIsBrandPopupOpen={setIsBrandPopupOpen} />
            <CategoryPopup isCategoryPopupOpen={isCategoryPopupOpen} setIsCategoryPopupOpen={setIsCategoryPopupOpen} />
        </>
    )
}

export default observer(DesktopHeader)