import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import { NavLink } from 'react-router-dom';
import { AxiosGet } from '../../services/AxiosRequests';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
const ImageSlider = () => {
	const [shoes, setShoes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		const getBanners = async () => {
			try {
				const response = await AxiosGet('/Service/banners');
				setShoes(response.data);
				console.log(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching banners:', error);
			}
		};

		getBanners();
	}, []);

	useEffect(() => {
		const handleResize = (event) => {
			setWidth(event.target.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (

		<>
			{loading ?
				(<LoadingAnimation></LoadingAnimation>)
				:
				(
					<div className="slider-container">
						{width < 600 ? (
							<Slider className="center" {...settings}>
							{shoes.mobile.map((item, index) => (
								<>
									<NavLink to={item.slug} >
										<div className="slide">
											{loading === true ? <></> : <img src={process.env.REACT_APP_IMG_URL+item.url} alt={'logo1'} />}
										</div>
									</NavLink>
								</>
							))}
						</Slider>
						):(
							<Slider className="center" {...settings}>
							{shoes.desktop.map((item, index) => (
								<>
									<NavLink to={item.slug} >
										<div className="slide">
											{loading === true ? <></> : <img src={process.env.REACT_APP_IMG_URL+item.url} alt={'logo1'} />}
										</div>
									</NavLink>
								</>
							))}
						</Slider>
						)}
					</div>
				)


			}
		</>
	);
};

export default ImageSlider;
{/* {width < 600 ?  */ }