import React from 'react'
import './Refund.css'
export default function Refund() {
  return (
    <div className='refund'>
      <h1 className='refund_h1'>Возвраты</h1>

      <p className='refund_refunds'>Мы стремимся обеспечить ваше полное удовлетворение от покупок, поэтому 
        предоставляем возможность возврата товара в определенных случаях. Ниже приведены условия и процедуры для возврата товара и возврата средств.</p>

      <h2>Условия возврата</h2>

      <p>Для осуществления возврата товара необходимо соблюдение следующих условий:</p>

      <ul>
        <li>Товар должен быть в оригинальной упаковке и сохранять свой первоначальный вид.</li>
        <li>Срок для подачи заявки на возврат - [указать количество дней] дней с момента получения товара.</li>
        <li>Товар не должен быть использован, и должны быть сохранены все заводские ярлыки и бирки.</li>
      </ul>

      <h2>Процедура возврата</h2>

      <p>Для осуществления возврата выполните следующие шаги:</p>

      <ol>
        <li>Свяжитесь с нашей службой поддержки по почте или номеру телефона и уточните возможность возврата товара.</li>
        <li>Упакуйте товар в оригинальную упаковку с учетом требований сохранения первоначального вида.</li>
        <li>Доставьте товар в ближайший пункт выдачи, указанный нашим менеджером.</li>
        <li>После получения и проверки товара мы произведем возврат средств в течение [указать количество дней] рабочих дней.</li>
      </ol>

      <h2>Возврат средств</h2>

      <p>Возврат средств будет осуществлен на тот же способ, которым была произведена оплата. 
        Сроки зачисления средств могут зависеть от банка или платежной системы, используемой для оплаты.</p>

      <p>Мы гарантируем прозрачность и справедливость в процессе возвратов. Если у вас возникли вопросы 
        или затруднения, не стесняйтесь обращаться к нашей службе поддержки.</p>
    </div>
  )
}
