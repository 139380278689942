import React from 'react'
import './UserAgreement.css'
export default function UserAgreement() {
  return (
    <div className='user_agreement'>
      <h2>Пользовательское соглашение на обработку персональных данных интернет-магазином</h2>

      <p>1. Настоящее соглашение определяет условия и порядок обработки персональных данных пользователей сайта www.kanturstore.ru, расположенного на доменном имени kantur.ru, (далее - Интернет-магазин).</p>

      <p>2. Принимая условия настоящего соглашения и проставляя "галочку" в соответствующем поле "Принимаю пользовательское соглашение", Пользователь:</p>
      <ul>
        <li>подтверждает, что все указанные им данные принадлежат лично ему;</li>
        <li>подтверждает и признает, что им внимательно в полном объеме прочитано настоящее соглашение и условия обработки его персональных данных, указываемых им в полях форм, текст соглашения и условия обработки персональных данных ему понятны;</li>
        <li>дает согласие на обработку Интернет-магазином предоставляемых в составе информации персональных данных в целях заключения между ним и Интернет-магазином настоящего соглашения, а также его последующего исполнения;</li>
        <li>дает согласие на направление уведомлений об изменении заказов, предложений и другой информации;</li>
        <li>дает согласие на направление информации о товарах и услугах, которые, по мнению администрации Интернет-магазина, могут представлять интерес;</li>
        <li>дает согласие на проведение опросов и маркетинговых, статистических и других исследований;</li>
        <li>дает согласие на получение новостной рассылки и иных сведений от имени Интернет-магазина или от имени партнеров Интернет-магазина;</li>
        <li>выражает согласие с условиями обработки персональных данных.</li>
      </ul>

      <p>3. Пользователь выражает свое согласие на обработку его персональных данных, а именно совершение любых действий (операций) или совокупности действий (операций) с персональными данными.</p>

      <p>4. Давая такое согласие, Пользователь подтверждает, что он действует свободно, своей волей и в своем интересе.</p>

      <p>5. Согласие Пользователя на обработку персональных данных является конкретным, информированным и сознательным.</p>

      <p>Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения, оформляется отдельно от иных согласий субъекта персональных данных на обработку его персональных данных.</p>

      <p>6. Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Покупателя, такая как:</p>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>дата рождения;</li>
        <li>контактный телефон;</li>
        <li>адрес электронной почты;</li>
        <li>почтовый адрес.</li>
      </ul>

      <p>7. Интернет-магазин обрабатывает и использует персональные данные Пользователя в целях:</p>
      <ul>
        <li>идентификации Пользователя, зарегистрированного на сайте Интернет-магазина, для оформления заказа и (или) заключения договора купли-продажи товара дистанционным способом с Интернет-магазином;</li>
        <li>предоставления Пользователю доступа к персонализированным ресурсам сайта Интернет-магазина;</li>
        <li>установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта Интернет-магазина, оказания услуг, обработки запросов и заявок от Пользователя;</li>
        <li>создания учетной записи для совершения покупок;</li>
        <li>уведомления Пользователя сайта Интернет-магазина о состоянии заказа;</li>
        <li>предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта Интернет-магазина;</li>
        <li>предоставления Пользователю обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Интернет-магазина или от имени партнеров Интернет-магазина;</li>
        <li>осуществления рекламной деятельности.</li>
      </ul>
      <p>8. Пользователь вправе в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию в личном кабинете в части Ф. И. О., номера телефона, пароля, адреса для доставки заказов.</p>

      <p>9. Интернет-магазин не проверяет достоверность предоставленной Пользователем персональной информации и не осуществляет контроль её актуальности. Интернет-магазин исходит из того, что Пользователь предоставляет достоверную персональную информацию и поддерживает эту информацию в актуальном состоянии. Вся ответственность, а также возможные последствия предоставления недостоверной или неактуальной персональной информации несёт Пользователь.</p>

      <p>10. Пользователь вправе отказаться от рекламных рассылок по номеру мобильного телефона и/или адресу электронной почты в любое время.</p>

      <p>11. Персональные данные Пользователя хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</p>

      <p>12. Интернет-магазин обязуется принимать все необходимые меры для защиты персональных данных Пользователя, предусмотренные действующим законодательством в области защиты персональных данных.</p>

      <p>13. Интернет-магазин обязуется соблюдать конфиденциальность персональных данных - не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено Федеральным законом от 27 июля 2006 г. N 152-ФЗ "О персональных данных".</p>

      <p>14. Пользователь вправе отказаться от подтверждения соглашения, в случае если какое-либо условие является для Пользователя неприемлемым.</p>

      <p>15. Согласие на обработку персональных данных может быть отозвано Пользователем на сайте Интернет-магазина через чат или форму обратной связи, указав Ф. И. О. и номер телефона.</p>

      <p>16. Интернет-магазин хранит информацию по заказам Пользователя в течение одного года с даты заказа.</p>

      <p>17. Интернет-магазин вправе вносить изменения в настоящее соглашение. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция соглашения вступает в силу с момента её размещения на сайте Интернет-магазина.</p>


    </div>
  )
}
