import React, { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import "./Search.css";
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { AxiosGet } from '../../services/AxiosRequests';
import ProductItem from '../ProductItem/ProductItem';
import { useParams } from 'react-router-dom';

function Search() {
	const { Slug } = useParams();
	const [shoes1, setShoes1] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchData1() {
			setLoading(true);
			try {

				const response = await AxiosGet('/Store/Search_b/?search=' + Slug);
				if (response.status === 200) {
					setShoes1(response.data);
					setLoading(false);


				}
			}
			catch (error) {
				console.error('Произошла ошибка:', error);
			}
		}
		fetchData1();
	}, [Slug]);


	return (
		<>
			{loading ? (
				<LoadingAnimation>
				</LoadingAnimation>
			) : (
				<>
					<div className={window.innerWidth > 768 ? 'shoes_main' : 'shoes_main mobile'}  >
						<div className='shoes_h2'><h1>Поиск</h1></div>
						<div className='shoes_products'>
							<div className="shoes_page_items">
								{shoes1.length > 0 ? (
									<>
										{shoes1.map((item, index) => (
											<div className='shoes_page_item'>
												<ProductItem item={item} key={index}></ProductItem>
											</div>
										))}
									</>
								) : (
									<div>Ничего не найдено</div>
								)
								}

							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default Search;