import React from 'react'
import './FilterMobile.css'
import { useState, useEffect } from 'react';
import { AxiosGet } from '../../../services/AxiosRequests';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useNavigate } from 'react-router-dom';
export default function FilterMobile({ isActive, setIsActive, queryParams }) {

	const [brands, setBrands] = useState([]);
	const [categorys, setCategorys] = useState([]);
	const [colors, setColors] = useState([]);
	const [models, setModels] = useState([]);
	const sizes = [35, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 42, 43, 44, 45];
	const [priceRange, setPriceRange] = useState([0, 30000]);
	const genderTranslate = { 'Мужской': 'male', 'Женский': 'female', 'Унисекс': 'unisex' }

	const [selectedGender, setSelectedGender] = useState(queryParams && queryParams.gender ? queryParams.gender : []);
	const [selectedCategorys, setSelectedCategorys] = useState(queryParams && queryParams.category ? queryParams.category : []);
	const [selectedColors, setSelectedColors] = useState(queryParams && queryParams.color ? queryParams.color : []);
	const [selectedBrands, setSelectedBrands] = useState(queryParams && queryParams.brand ? queryParams.brand : []);
	const [selectedModels, setSelectedModels] = useState(queryParams && queryParams.model ? queryParams.model : []);
	const [selectedSizes, setSelectedSizes] = useState(queryParams && queryParams.size ? queryParams.size : []);
	const [selectedSale, setSelectedSale] = useState(queryParams && queryParams.sale && queryParams.sale[0] ? queryParams.sale[0]==='true' ? true : false : false );
	
	const [isOpenPrice,setIsOpenPrice] = useState(false);
	const [isOpenGender,setIsOpenGender] = useState(false);
	const [isOpenCategory,setIsOpenCategory] = useState(false);
	const [isOpenColor,setIsOpenColor] = useState(false);
	const [isOpenBrand,setIsOpenBrand] = useState(false);
	const [isOpenModel,setIsOpenModel] = useState(false);
	const [isOpenSize,setIsOpenSize] = useState(false);
	

	const navigate = useNavigate();

	useEffect(() => {
		async function fetchData() {
			const brands = await AxiosGet('/Store/brand');
			setBrands(brands.data.brands);
			const categorys = await AxiosGet('/Store/categorys');
			setCategorys(categorys.data.categorys);
			console.log(categorys.data.categorys);
			const colors = await AxiosGet('/Store/colors');
			setColors(colors.data.colors);
			const models = await AxiosGet('/Store/models_f');
			setModels(models.data.models);
		};
		fetchData();
	}, []);

	const handlePriceChange = (value) => {
		setPriceRange(value);
	};

	const setSingleState = (stateToUpdate) => {
		setIsOpenPrice(stateToUpdate === 'isOpenPrice' && !isOpenPrice);
		setIsOpenGender(stateToUpdate === 'isOpenGender' && !isOpenGender);
		setIsOpenCategory(stateToUpdate === 'isOpenCategory' && !isOpenCategory);
		setIsOpenColor(stateToUpdate === 'isOpenColor' && !isOpenColor);
		setIsOpenBrand(stateToUpdate === 'isOpenBrand' && !isOpenBrand);
		setIsOpenModel(stateToUpdate === 'isOpenModel' && !isOpenModel);
		setIsOpenSize(stateToUpdate === 'isOpenSize' && !isOpenSize);
	};
	

	const applyFilters = async () => {
		try {
			const params = new URLSearchParams();

			selectedGender.forEach((gender) => {
				params.append('gender', gender);
			});

			selectedCategorys.forEach((category) => {
				params.append('category', category);
			});
			selectedColors.forEach((color) => {
				params.append('color', color);
			});

			selectedBrands.forEach((brand) => {
				params.append('brand', brand);
			});

			selectedModels.forEach((model) => {
				params.append('model', model);
			});

			selectedSizes.forEach((size) => {
				params.append('size', size);
			});

			params.append('sale', selectedSale);

			params.append('min_price', priceRange[0]);
			params.append('max_price', priceRange[1]);
			navigate('/shoes/filter/' + params.toString() + "&page=1");
			setIsActive(false);

		} catch (error) {
			console.error('Произошла ошибка при запросе фильтров:', error);
		}
	};

	const handleTooltip = (value) => {
		return `${value} ₽`;
	};

	const handleGenderClick = (gender) => {
		if (selectedGender.includes(genderTranslate[gender])) {
			setSelectedGender(selectedGender.filter((selectedGender) => selectedGender !== genderTranslate[gender]));
		} else {
			setSelectedGender([...selectedGender, genderTranslate[gender]]);
		}
	};

	const handleCategoryClick = (category) => {
		if (selectedCategorys.includes(category)) {
			setSelectedCategorys(selectedCategorys.filter((selectedCategory) => selectedCategory !== category));
		} else {
			setSelectedCategorys([...selectedCategorys, category]);
		}
	};

	const handleColorClick = (color) => {
		if (selectedColors.includes(color)) {
			setSelectedColors(selectedColors.filter((selectedColor) => selectedColor !== color));
		} else {
			setSelectedColors([...selectedColors, color]);
		}
	};

	const handleBrandClick = (brand) => {
		if (selectedBrands.includes(brand)) {
			setSelectedBrands(selectedBrands.filter((selectedBrand) => selectedBrand !== brand));
		} else {
			setSelectedBrands([...selectedBrands, brand]);
		}
	};

	const handleModelClick = (model) => {
		if (selectedModels.includes(model)) {
			setSelectedModels(selectedModels.filter((selectedModel) => selectedModel !== model));
		} else {
			setSelectedModels([...selectedModels, model]);
		}
	};

	const handleSizeClick = (size) => {
		if (selectedSizes.includes(size.toString())) {
			setSelectedSizes(selectedSizes.filter((selectedSize) => selectedSize.toString() !== size.toString()));
		} else {
			setSelectedSizes([...selectedSizes, size.toString()]);
		}
	};

	const handleSaleClick = () => {
        setSelectedSale(!selectedSale)
    };

	useEffect(() => {
		setSelectedGender(queryParams && queryParams.gender ? queryParams.gender : []);
		setSelectedCategorys(queryParams && queryParams.category ? queryParams.category : []);
		setSelectedColors(queryParams && queryParams.color ? queryParams.color : []);
		setSelectedBrands(queryParams && queryParams.brand ? queryParams.brand : []);
		setSelectedModels(queryParams && queryParams.model ? queryParams.model : []);
		setSelectedSizes(queryParams && queryParams.size ? queryParams.size : [])
		setSelectedSale(queryParams && queryParams.sale && queryParams.sale[0] ? queryParams.sale[0]==='true' ? true : false : false );
	}, [queryParams])

	return (
		<div className={isActive ? 'filter_mobile_current active' : 'filter_mobile_current'} onClick={() => { setIsActive(false) }}>
			<div className='filter_mobile_content' onClick={(e) => e.stopPropagation()}>

				<div className='filter_mobile_content_header'>
					<h2>Фильтры</h2>
					<button onClick={() => { setIsActive(false) }} ><img src='/static/krest.svg' /> </button>
				</div>

				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenPrice');console.log('test')}}>
						<p>Цены</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>
					
				</div>

				<div className={isOpenPrice ? 'filter_mobile_content_price active' : 'filter_mobile_content_price'}>		
					
					<div className="mobile_filter_content_price_custom_tooltip">
						{priceRange.map((value, index) => (
							<div key={index} className="tooltip-item">
								{handleTooltip(value)}
							</div>
						))}
					</div>

					<Slider
						range
						min={0}
						max={30000}
						defaultValue={priceRange}
						onChange={handlePriceChange}
						tipFormatter={handleTooltip}
						trackStyle={{ backgroundColor: "black", height: 4}}
						dotStyle={{ backgroundColor: "black", }}
						pushable
					/>
					<div className='desktop_filter_content_price_sale_picker'>
                    <input
                        type='checkbox'
                        onChange={() => { handleSaleClick() }}
                        checked={selectedSale}
                    />
                    <p onClick={()=>{handleSaleClick()}} >{'Только товары по скидке'}</p>
                </div>
				</div>
				
				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenGender')}}>
						<p>Пол</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>
					
				</div>
				
				<div className={isOpenGender ? 'mobile_filter_content_genders active':'mobile_filter_content_genders'}>
					{['Мужской', 'Женский', 'Унисекс'].map((gender) => (
						<div className='mobile_filter_content_gender'>
							<input
								type='checkbox'
								onChange={() => { handleGenderClick(gender) }}
								checked={selectedGender.includes(genderTranslate[gender])}
							/>
							<p onClick={() => { handleGenderClick(gender) }} >{gender}</p>
						</div>
					))}
				</div>

				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenCategory')}}>
						<p>Категории</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>
				</div>
				
				<div className={isOpenCategory ? 'mobile_filter_content_categorys active' : 'mobile_filter_content_categorys'}>
					<div className='mobile_filter_content_categorys_list'>
						{categorys.map((category) => (
							<div className='mobile_filter_content_category'>
								<input
									type='checkbox'
									onChange={() => { handleCategoryClick(category.en) }}
									checked={selectedCategorys.includes(category.en)}
								/>
								<p onClick={() => { handleCategoryClick(category.en) }} >{category.ru}</p>
							</div>
						))}
					</div>
				</div>

				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenColor')}}>
						<p>Цвета</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>					
				</div>

				<div className={isOpenColor ? 'mobile_filter_content_colors active' : 'mobile_filter_content_colors'}>
					<div className='mobile_filter_content_colors_list'>
						{colors.map((color) => (
							<div className='mobile_filter_content_color'>
								<input
									type='checkbox'
									onChange={() => { handleColorClick(color.en) }}
									checked={selectedColors.includes(color.en)}
								/>
								<p onClick={() => { handleColorClick(color.en) }} >{color.en}</p>
							</div>
						))}
					</div>
				</div>

				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenBrand')}} >
						<p>Бренды</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>
				</div>

				<div className={isOpenBrand ? 'mobile_filter_content_brands active' : 'mobile_filter_content_brands'}>
					<div className='mobile_filter_content_brands_list'>
						{brands.map((brand) => (
							<div className='mobile_filter_content_brand'>
								<input
									type='checkbox'
									onChange={() => { handleBrandClick(brand) }}
									checked={selectedBrands.includes(brand)}
								/>
								<p onClick={() => { handleBrandClick(brand) }} >{brand}</p>
							</div>
						))}
					</div>
				</div>

				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenModel')}}>
						<p>Модели</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>
					
				</div>

				<div className={isOpenModel ? 'mobile_filter_content_models active' : 'mobile_filter_content_models'}>
					<div className='mobile_filter_content_models_list'>
						{models.map((model) => (
							<div className='mobile_filter_content_model'>
								<input
									type='checkbox'
									onChange={() => { handleModelClick(model) }}
									checked={selectedModels.includes(model)}
								/>
								<p onClick={() => { handleModelClick(model) }} >{model}</p>
							</div>
						))}
					</div>
				</div>

				<div className='filter_mobile_content_button'>
					<button onClick={()=>{setSingleState('isOpenSize')}}>
						<p>Размеры</p>
						<img src="/static/footer_down_arrow_black.svg" />
					</button>
					
				</div>

				<div className={isOpenSize ? 'mobile_filter_content_sizes active' : 'mobile_filter_content_sizes'}>
					<div className='mobile_filter_content_sizes_list'>
						{sizes.map((size) => (
							<div className='mobile_filter_content_size'>
								<input
									type='checkbox'
									onChange={() => { handleSizeClick(size) }}
									checked={selectedSizes.includes(size.toString())}
								/>
								<p onClick={() => { handleSizeClick(size) }} >{size} EU</p>
							</div>
						))}
					</div>
				</div>
				

				<div className='mobile_filter_content_confirm'>
					<button onClick={() => { applyFilters() }}>Применить <p>{selectedCategorys.length + selectedBrands.length+selectedColors.length + selectedSizes.length + selectedGender.length + selectedModels.length}</p></button>
					<button onClick={() => { navigate('/shoes') }}>Сборсить</button>
				</div>
				
			</div>
		</div>
	)
}
