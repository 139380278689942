import React from 'react'
import './Contacts.css'
export default function Contacts() {
    return (
        <div className='contacts'>
            <h1>Контакты магазина</h1>

            <h2>Информация о компании</h2>

            <p>Наименование компании: ИП Кантур Павел Александрович</p>
            <p>ОГРНИП: 319169000103634</p>
            <p>ИНН: 164511624504</p>

            <h2>Контактная информация</h2>

            <p>Телефон: +7 (925) 224-79-22</p>
            <p>Электронная почта: <a href="mailto:info@kanturstore.ru">info@kanturstore.ru</a></p>

            <h2>Адрес</h2>

            <p>г. Балашиха, мкр. Железнодорожный, ул. Маяковского 5к2</p>

            <h2>Обратная связь</h2>

            <p>Если у вас есть вопросы, предложения или нужна дополнительная информация, свяжитесь с нами удобным для вас способом. Мы ценим ваше внимание и готовы помочь!</p>

            <p>Спасибо, что выбрали нас!</p>

        </div>
    )
}
