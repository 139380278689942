import React from 'react'
import { NavLink } from'react-router-dom'
import './ProfileModal.css'
export default function ProfileModal({isAuthenticated,setisModalProfileOpen,isModalProfileOpen}) {
	function Logout() {
		localStorage.clear()
		setisModalProfileOpen(false)
		window.location.replace('/')
	}
	return (
		<div className={isModalProfileOpen & isAuthenticated ? 'profile_popup active' : 'profile_popup'}>
			<div className='profile_button'>
				<NavLink to="/Account" id="profile">
					<button onClick={() => { setisModalProfileOpen(!isModalProfileOpen) }}>
						<img src="/static/profile.svg" alt="profile" />
						<p>Профиль</p>
					</button>
				</NavLink>
			</div>
			<div className='profile_button'><button onClick={() => { Logout() }}><p>Выйти</p></button></div>
		</div>
	)
}
