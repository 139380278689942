import { AxiosPost } from "./AxiosRequests";
import {jwtDecode} from 'jwt-decode';

function decodeAccessToken(accessToken) {
  try {
    const decoded = jwtDecode(accessToken);
    return decoded;
  } catch (error) {
    return null;
  }
}

function isAccessTokenValid() {
	const accessToken = localStorage.getItem('accessToken');
	if (!accessToken) {
		return false;
	}
	const decodedToken = decodeAccessToken(accessToken);
	if (!decodedToken) {
		return false;
	}
	const currentTime = Math.floor(Date.now() / 1000);
	if (decodedToken.exp > currentTime) {
		return true;
	}
	return false;
}

async function refreshAccessToken() {
	const refreshToken = localStorage.getItem('refreshToken');
	if (!refreshToken) {
		return false;
	}
	try {
		const response = await AxiosPost('/Users/RefreshToken', { "refresh_token": refreshToken });
		if (response.status === 200) {
			const newAccessToken = response.data.access_token;
			localStorage.setItem('accessToken', newAccessToken);
			return true;
		}

		return false;
	} catch (error) {
		return false;
	}
}
let isRefreshing = false;

async function checkAuthentication() {
	if (isAccessTokenValid()) {
		return true;
	} else if (!isRefreshing) {
		isRefreshing = true;

		try {
			const success = await refreshAccessToken();
			if (success) {
				return true;
			} else {
				localStorage.removeItem('accessToken');
				localStorage.removeItem('refreshToken');
				return false;
			}
		} finally {
			isRefreshing = false;
		}
	} else {
		await new Promise(resolve => setTimeout(resolve, 100));
		return checkAuthentication();
	}
}
export { checkAuthentication, refreshAccessToken };

