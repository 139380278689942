import './App.css';
import Account from './components/account/Account';
import Faq from './components/faq/Faq';
import Layout from './components/layout/Layout';
import Main from './components/main/Main';
import Prodpage from './components/prodpage/Prodpage';
import CreateOrder from './components/CreateOrder/CreateOrder';
import Search from './components/Search/Search';
import Order from './components/Order/Order';
import Delivery from './components/Delivery/Delivery';
import Payment from './components/Payment/Payment';
import Refund from './components/Refund/Refund';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import UserAgreement from './components/UserAgreement/UserAgreement';
import OfferAgreement from './components/OfferAgreement/OfferAgreement';
import Contacts from './components/Contacts/Contacts';
import ShoesMain from './components/shoes/main/main';
import ShoesPagination from './components/shoes/pagination/pagination';
import FilterMain from './components/shoes/FilterMain/FilterMain';
import { Routes, Route } from "react-router-dom";



function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Layout />} >
					<Route index element={<Main />} />
					<Route path="FAQ" element={<Faq />} />
					<Route path="/shoes" element={<ShoesMain/>}></Route>
					<Route path="/shoes/page/:number" element={<ShoesPagination/>}></Route>
					<Route path="/shoes/filter/:params" element={<FilterMain/>}></Route>
					<Route path="/shoes/:Slug" element={<Prodpage></Prodpage>}></Route>
					<Route path="/account" element={<Account />} />
					<Route path="/create-order" element={<CreateOrder></CreateOrder>}></Route>
					<Route path="/search/:Slug" element={<Search></Search>}></Route>
					<Route path="/Account/Order/:Slug" element={<Order></Order>}></Route>
					<Route path="/delivery" element={<Delivery></Delivery>}></Route>
					<Route path="/payment" element={<Payment></Payment>}></Route>
					<Route path="/delivery" element={<Delivery></Delivery>}></Route>
					<Route path="/refund" element={<Refund></Refund>}></Route>
					<Route path="/privacy-policy" element={<PrivacyPolicy></PrivacyPolicy>}></Route>
					<Route path="/offer-agreement" element={<OfferAgreement></OfferAgreement>}></Route>
					<Route path="/user-agreement" element={<UserAgreement></UserAgreement>}></Route>
					<Route path="/contacts" element={<Contacts></Contacts>}></Route>
				</Route>

			</Routes>
		</>
	);
}

export default App;
