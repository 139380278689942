import React from 'react'
import "./Payment.css"
export default function Payment() {
  return (
    <div className='payment1'>
      <h1 className='payment1_h1'>Оплата</h1>

      <p className='payment_payments'>Мы стремимся сделать процесс оплаты максимально удобным для наших клиентов. 
      Предлагаем различные варианты оплаты, чтобы соответствовать вашим предпочтениям и 
      обеспечить безопасность транзакций.</p>

      <h2>Банковские карты</h2>
      <p>Мы принимаем оплату банковскими картами Visa, MasterCard и другими популярными системами. 
        Оплата происходит в защищенном режиме, используя современные технологии шифрования, 
        что гарантирует безопасность ваших финансовых данных.</p>

      <h3>Системы быстрых платежей</h3>
      <p>Для мгновенных транзакций мы поддерживаем системы быстрых платежей, 
        такие как Яндекс.Деньги, QIWI, WebMoney и другие. 
        Вы можете выбрать наиболее удобный для вас способ оплаты и 
        завершить покупку всего в несколько кликов.</p>

      <h3>SberPay</h3>
      <p>SberPay - это удобный и безопасный способ оплаты через мобильное приложение Сбербанка. 
        Просто выберите SberPay при оформлении заказа, отсканируйте QR-код и подтвердите оплату в приложении. 
        Это быстро и надежно!</p>

      <h2>Подтверждение оплаты</h2>
      <p>После успешной оплаты вы получите уведомление на указанный электронный адрес с 
        подтверждением заказа и деталями транзакции. Мы ценим ваше доверие и делаем все возможное, 
        чтобы обеспечить прозрачность и надежность процесса оплаты.</p>

      <p>Спасибо за выбор нас! Если у вас возникнут вопросы или вам понадобится дополнительная информация, 
        не стесняйтесь обращаться к нашей службе поддержки.</p>
    </div>
  )
}
