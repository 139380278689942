import { NavLink } from "react-router-dom";
import "./ProductItem.css";

function translate(gender) {
	if (gender === 'male') {
		return ('Мужской');
	} else if (gender === 'female') {
		return ('Женский');
	} else if (gender === 'unisex') {
		return ('Унисекс');
	}
}

function ProductItem({ item, id, setIsActive }) {
	
	function getColorString() {
		if (item.colors && item.colors.length > 0) {
			return item.colors.map(item => item.name).join(', ');
		}
		return 'Нет данных о цветах';
	}

	return (
		<>
			<div className="item" id={id}>
				<NavLink to={'/Shoes/' + item.slug} >
					<div className="item_prev">
						<img src={process.env.REACT_APP_IMG_URL+item.photo} alt={item.slug} />
					</div>
					{item.price.discount_flag ? (
							<div className="price">
								<div className="actual_price"><p>{item.price.discount_price + ' ₽'}</p></div>
								<div className="oldprice"><p>{item.price.end_price + ' ₽'}</p></div>
							</div>
						) : (
							<div className="price">
								<div className="actual_price"><p>{item.price.end_price + ' ₽'}</p></div>
							</div>
						)}
					<div className="description">
						<p className="nameprod">{item.name}</p>
						<p className="gender_item">{translate(item.gender)}</p>
						{item.colors.length>1?(<p className="color">Цвета: {getColorString()}</p>):(<p className="color">Цвет: {getColorString()}</p>)}
						
					</div>
				</NavLink>
			</div>
		</>
	);
}

export default ProductItem;
