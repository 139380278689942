import React from 'react'
import CartStore from '../../../../Stores/Cart'
import { NavLink } from 'react-router-dom'
import './Cart.css'
import { observer } from 'mobx-react';
function Cart({ isActive, setIsActive,openModal,isAuthenticated }) {
	return (
		<>
			<div className={!isActive ? 'cart_popup' : 'cart_popup active'}>
				<>
					<div className='cart_popup_header'>
						<div className='cart_popup_header_left'>
							<div className='cart_popup_header_Korzina'>Корзина</div>
							<div className='cart_popup_header_count'>{CartStore.cart.length} товара</div>
						</div>
						<div className='cart_popup_header_right'>
							<button onClick={() => { CartStore.DeleteItemCart() }}>
								<img src="/static/Delete.svg" alt="delete" />
							</button>
						</div>
					</div>
					<div className='cart_popup_items'>
						{CartStore.cart.map((item, index) => (
							<li key={index} className="cart_popup_item">
								<div className='cart_popup_item_firstline'>
									<div className='cart_popup_item_left'>
										<img src={process.env.REACT_APP_IMG_URL + item.img} alt={item.name} />
									</div>
									<div className='cart_popup_item_center'>
										<div className='cart_popup_item_center_name'>
											<NavLink to={'/shoes/'+item.slug} >{item.name}</NavLink>
										</div>
										<div className='cart_popup_item_center_gender'>
											{item.gender}
										</div>
									</div>
									<div className='cart_popup_item_right'>
										{item.price.discount_flag ? (
											<>
												<p className="cart_popup_item_right_discount">{item.price.discount_price} ₽</p>
												<p className="cart_popup_item_right_crossed">{item.price.end_price} ₽</p>
											</>
										) : (
											<p className="cart_popup_item_right_default">{item.price.end_price} ₽</p>
										)}

									</div>
								</div>
								<div className='cart_popup_item_subline'>
									{item.count === 2 ? (<p className='cart_popup_item_center_out_of_2'>Это максимальное количество доступное для заказа</p>) : (<p>.</p>)}
								</div>
								<div className='cart_popup_item_secondline'>
									<button className='cart_popup_item_secondline_minus' onClick={() => { CartStore.ChangeItemCount(item, 'minus') }}><img src='/static/minus.svg' alt='minus' ></img></button>
									<p>{item.count}</p>
									{item.count < 2 && CartStore.total_quantity < 5 ? (<button className='cart_popup_item_secondline_plus' onClick={() => { CartStore.ChangeItemCount(item, 'plus') }} ><img src='/static/plusorder.svg' alt='plus' ></img></button>) : (<button disabled></button>)}
									<p>{item.price.size.size_ru} размер </p>
								</div>

							</li>
						))}
					</div>
					{!isAuthenticated ? (
						<>
							{CartStore.total_count > 0 ? (
								<div className='cart_popup_confirm'>
									<button onClick={() => openModal()}>Оформить заказ</button>
								</div>
							) :
								(<></>)

							}
						</>
					) : (
						<>
							{CartStore.total_count > 0 ? (
								<div className='cart_popup_confirm'>
									<NavLink to={'/create-order'} onClick={() => setIsActive(false)}>
										<button onClick={() => setIsActive(false)}>Оформить заказ</button>
									</NavLink>
								</div>

							) :
								(<></>)

							}
						</>
					)}

				</>
			</div>

		</>
	)
}

export default observer(Cart);
