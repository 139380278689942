import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AuthModal from '../AuthModal/AuthModal';
import { checkAuthentication } from "../../services/AuthService";
import { observer } from 'mobx-react';
import './header.css'

import Search from './components/Search/Search';
import Cart from './components/Cart/Cart';
import DesktopHeader from './components/DesktopHeader/DesktopHeader';
import MobileHeader from './components/MobileHeader/MobileHeader';
import ProfileModal from './components/ProfileModal/ProfileModal';
function Header() {
	const [isModalAuthOpen, setIsModalAuthOpen] = useState(false);
	const [isModalCartOpen, setIsModalCartOpen] = useState(false);
	const [isModalProfileOpen, setisModalProfileOpen] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isModalSearchOpen, setIsModalSearchOpen] = useState(false);

	const openModal = () => {
		setIsModalAuthOpen(true);
		setIsModalCartOpen(false);
		setisModalProfileOpen(false);
	};

	useEffect(() => {
		async function fetchdata() {
			const isAuth = await checkAuthentication();
			setIsAuthenticated(isAuth);
		}
		fetchdata();
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			const cartPopup = document.querySelector('.cart_popup');
			if (cartPopup && !cartPopup.contains(event.target)) {
				setIsModalCartOpen(false);
			}
		};

		if (isModalCartOpen) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isModalCartOpen]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			const profilePopup = document.querySelector('.profile_popup');
			if (profilePopup && !profilePopup.contains(event.target)) {
				setisModalProfileOpen(false);
			}
		};

		if (isModalProfileOpen) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isModalProfileOpen]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			const searchPopup = document.querySelector('.search_popup');
			if (searchPopup && !searchPopup.contains(event.target)) {
				setIsModalSearchOpen(false);
			}
		};

		if (isModalSearchOpen) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isModalSearchOpen]);

	return (
		<>
			<header className="main_header">
				{window.innerWidth>768 ?
				<DesktopHeader
				isModalCartOpen={isModalCartOpen} setIsModalCartOpen={setIsModalCartOpen}
				isModalProfileOpen={isModalProfileOpen} setisModalProfileOpen={setisModalProfileOpen}
				isModalSearchOpen={isModalSearchOpen} setIsModalSearchOpen={setIsModalSearchOpen}
				isModalAuthOpen={isModalAuthOpen} setIsModalAuthOpen={setIsModalAuthOpen}
				isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}
				/> 
				:
				<MobileHeader
				isModalCartOpen={isModalCartOpen} setIsModalCartOpen={setIsModalCartOpen}
				isModalProfileOpen={isModalProfileOpen} setisModalProfileOpen={setisModalProfileOpen}
				isModalSearchOpen={isModalSearchOpen} setIsModalSearchOpen={setIsModalSearchOpen}
				isModalAuthOpen={isModalAuthOpen} setIsModalAuthOpen={setIsModalAuthOpen}
				isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}
				/>
				}
				
				<Cart isActive={isModalCartOpen} setIsActive={setIsModalCartOpen} openModal={openModal} isAuthenticated={isAuthenticated} />
				<Search isActive={isModalSearchOpen} setIsActive={setIsModalSearchOpen} />
				<ProfileModal isModalProfileOpen={isModalProfileOpen} setisModalProfileOpen={setisModalProfileOpen} isAuthenticated={isAuthenticated}/>
			</header>


			<AuthModal active={isModalAuthOpen} setActive={setIsModalAuthOpen} />
		</>

	);
}

export default observer(Header);
