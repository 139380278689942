import './Layout.css'
import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react'
function Layout() {
  return (

    <div className='layout'>
      <div className='header'>
      <Header className='header'></Header>
      </div>
      <div className='outlet'> 
      <Outlet className='outlet'></Outlet>
      </div>
      <Footer/>
    </div>

  )
}
export default observer(Layout)