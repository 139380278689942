import axios from "axios";
import { checkAuthentication } from "./AuthService";

const base_url = process.env.REACT_APP_API_URL


export async function AxiosGet(url, params) {
	try {
		const response = await axios.get(base_url+url, params );
		return response;
	} catch (error) {
		console.error('Error during GET request:', error);
		throw error;
	}
}

export async function AxiosAuthGet(url, params) {
	try {
		const isAuth = await checkAuthentication();
		if (isAuth) {
			const response = await axios.get(base_url+url, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
			return response;
		}
	} catch (error) {
		console.error('Error during GET request:', error);
		throw error;
	}
}

export async function AxiosAuthPost(url, data) {
	try {
		const isAuth = await checkAuthentication();
		if (isAuth) {
			const response = await axios.post(base_url+url, data, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
			return response;
		}
	} catch (error) {
		console.error('Error during POST request:', error);
		throw error;
	}
}

export async function AxiosPost(url, data) {
	try {
		const response = await axios.post(base_url+url, data);
		return response;
	} catch (error) {
		console.error('Error during POST request:', error);
		throw error;
	}
}