import React from 'react'
import "./faq.css"


export default function Faq() {
  return (
    <>

        <div className="faq_cont wrap">
          <h1>FAQ</h1>

          <div className="faq_item">
              <div className="question">
                  <h2>Чем занимается наш магазин?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Продаете только обувь?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Как купить понравившийся товар?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>А в друг вы меня обманите, когда переведу деньги?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Как правильно правильно выбрать нужный размер?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Как производится доставка?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Что делать, если пришел не тот товар?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Что делать, если товар пришел с браком?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Когда вернут деньги?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Вернут ли мне деньги за обратную пересылку?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Как я могу сделать возврат?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Возможен ли обмен товара?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Когда мой заказ будет отправлен и доставлен?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
          <div className="faq_item">
              <div className="question">
                  <h2>Какие размеры указаны на сайте?</h2>
                  <div className="arrow"><img className="question_img" src="static/arrowfaq.svg" alt="\/"/></div>
              </div>
          </div>
      </div>

    
    </>
  )
}
