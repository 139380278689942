import React, { useState, useEffect,useRef } from 'react';
import { YMaps, Map, SearchControl, ObjectManager } from '@pbe/react-yandex-maps';
import { AxiosGet } from '../../services/AxiosRequests';
import './CdekMap.css';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
const CdekMap = ({ CallbackFunction }) => {
	const mapState = {
		center: [55.751574, 37.573856],
		zoom: 12,
	};
	const mapRef = useRef(null);
	const [visiblePointsCdek, setVisiblePointsCdek] = useState([]);
	const [selectedPoint, setSelectedPoint] = useState(null);
	const active2 = false;
	const [features, setFeatures] = useState([]);
	const [loading, setloading] = useState(true);

	function selectPickupPoint(code) {
		CallbackFunction(code);
	}
	const fetchInitialData = async () => {
		try {
			const response = await AxiosGet('/Delivery/GetAllMapDataCdek');
			const data = await response.data;
			setVisiblePointsCdek(data.points);
			const features1 = data.points.map(point => ({
				type: "Feature",
				id: point.code,
				geometry: {
					type: "Point",
					coordinates: [point.gps[1], point.gps[0]]
				}
			}));
			setFeatures(features1)
			setloading(false)
		} catch (error) {
			console.error('Ошибка при выполнении запроса к серверу:', error);
		}
	};


	useEffect(() => {
		// Fetch initial data when the component mounts
		fetchInitialData();
	}, []);
	return (
		<>
			{loading ? (<LoadingAnimation></LoadingAnimation>) : (
				<div className='CdekMap'>
					<div className={active2 ? 'custom_map_choose active' : 'custom_map_choose'}>
						<img src='static/logocdek_v2.png' alt='logo_cdek'></img>
						{selectedPoint !== null ? (
							<>
								<div className='custom_map_choose_text'>
									<p className='custom_map_choose_text_lable'>Адресс:</p>
									<p className='custom-font'>{selectedPoint.address_full}</p>
									<p className='custom_map_choose_text_lable'>Время работы:</p>

									{selectedPoint.work_time.split(',').map((time, index) => (
										<p key={index}>{time.trim()}</p>
									))}


								</div>
								<div className='custom_map_choose_button'>
									<button onClick={() => { selectPickupPoint(selectedPoint.code) }}>Выбрать отделение</button>
								</div>
							</>
						) : (<></>)}
					</div>
					<div className='custom_map'>

						<YMaps query={{ apikey: 'd765e4be-14f5-413c-b566-98a145921731', suggest_apikey: '20466a2d-fe02-43c2-bf48-edfaffbe38dc' }}>
							<Map
								defaultState={{
									controls: [],
								}}
								options={{
									suppressMapOpenBlock: true,
									yandexMapDisablePoiInteractivity: true
								}}
								state={mapState}
								width="100%"
								height="100%"
								instanceRef={(map) => {
									if (map) {
										mapRef.current = map;
									}
								}}
							>
								{!loading ? (
									<ObjectManager
										options={{
											clusterize: true,
											gridSize: 70,
											geoObjectOpenBalloonOnClick: false
										}}
										objects={{
											preset: "islands#greenDotIcon"
										}}
										clusters={{
											preset: "islands#greenClusterIcons"
										}}
										defaultFeatures={features}
										instanceRef={ref => {
											if (ref && ref.objects) {
												ref.objects.events.add('click', (e) => {
													const objectId = e.get('objectId');
													setSelectedPoint(visiblePointsCdek.find(obj => obj.code === objectId));
													if (mapRef.current) {
														mapRef.current.setCenter([visiblePointsCdek.find(obj => obj.code === objectId).gps[1],visiblePointsCdek.find(obj => obj.code === objectId).gps[0]]);
													}
												});
											}
										}}
									/>

								) : (<></>)}
								<SearchControl
									options={{ float: 'right', noPlacemark: true }}
									events={{

									}}
								/>

							</Map>
						</YMaps>

					</div>
				</div>
			)}
		</>
	);
};

export default CdekMap;
