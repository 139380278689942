import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import "./Order.css"
import { AxiosAuthGet, AxiosAuthPost } from '../../services/AxiosRequests';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
export default function Order() {
	const [order, setOrder] = useState([]);
	const [loading, setLoading] = useState(true);
	const { Slug } = useParams();
	useEffect(() => {
		async function fetchdata() {
			try {
				const response = await AxiosAuthGet('/Order/OrderDetailUser/' + Slug);
				setOrder(response.data);
				setLoading(false);
			}
			catch {

			}
		};
		fetchdata();
	}, [Slug]);
	async function DeliveryFind(){
		if (order.delivery.delivery_type==='cdek'){
			window.location.replace('https://www.cdek.ru/ru/tracking/?order_id='+order.delivery.track)
		}
		if(order.delivery.delivery_type==='boxberry'){
			window.location.replace('https://boxberry.ru/tracking-page')
			
		}
		
	}
	async function CancelOrder(){
		const response = await AxiosAuthPost('/Order/CancelOrder/' + Slug);
		if(response.status===200){
			window.location.reload()
		}
		
	}
	return (
		<>

			{loading ? (
				<>Загрузка</>
			) : (
				<>
					<div className='table_order'>
						<div className='table_order_top'>
							<div className='table_current_order_top'>
								<p>Заказ № {order.order_number} </p>
								<span>{order.status_ru}</span>
							</div>
						</div>
						<div className='table_order_bottom'>
							<div className='table_order_bottom_left'>
								{order.orderitem.map((order_item, index) => (
									<div className='table_order_bottom_left_item'>
										<div className='table_order_bottom_left_img'>
											<img src={process.env.REACT_APP_IMG_URL + order_item.product.photo} alt={order_item.product.name+index}/>
										</div>
										<div className='table_order_bottom_left_data'>
											<h5><NavLink to={'/Shoes/' + order_item.product.slug}>{order_item.product.name}</NavLink></h5>
											<p>Размер -{' ' + order_item.price.size.size_ru}</p>
											{order_item.price.discount_flag ?
												(
													<div className='table_order_bottom_left_data_price'>
														<p>Цена -</p>
														<h2 className="table_order_bottom_left_data_price_actualprice">{order_item.price.discount_price} ₽</h2>
														<s className="table_order_bottom_left_data_price_notactualprice">{order_item.price.end_price} ₽</s>
														<p className="table_order_bottom_left_data_price_discount">-{order_item.price.discount_percent}%</p>
													</div>
												)
												:
												(
													<div className='table_order_bottom_left_data_price'>
														<p>Цена -</p>
														<h2 className="table_order_bottom_left_data_price_actualprice">{order_item.price.end_price} ₽</h2>
													</div>
												)
											}
											<p>Кол-во - {order_item.quantity} </p>
										</div>
									</div>
								))}

							</div>
							<p className='Summa_order'><span className="table_current_order_bottom_right_span_p">Сумма:</span> {order.total_price} ₽</p>
							<div className='table_current_order_bottom_right'>
								<h2>Информация о заказе</h2>
								<div className='table_current_full_info'>
									<div className='table_current_full_info_delivery tcfiw'>
										<h3>Доставка:</h3>
										{order.delivery ? (
											<>
												<p><span className="table_current_order_bottom_right_span_p">Адресс: </span>{order.delivery.pvz_address}</p>
												<p><span className="table_current_order_bottom_right_span_p">Дата оформления:</span> {moment(order.created_at).locale('ru').format('D MMMM YYYY [года]')}</p>
												<p><span className="table_current_order_bottom_right_span_p">Ориентировочное время доставки: </span>{order.delivery.delivery_days}</p>
												<p><span className="table_current_order_bottom_right_span_p">Статус: </span>{order.delivery.delivery_status}</p>
												<p><span className="table_current_order_bottom_right_span_p">Метод доставки: </span>{order.delivery.delivery_type}</p>
												<p><span className="table_current_order_bottom_right_span_p">Трэк номер: </span>{order.delivery.track}</p>
												<p><span className="table_current_order_bottom_right_span_p">Цена: </span>{order.delivery.price} ₽</p>
											</>
										) : (<></>)}


										<div className={`table_current_find_delivery ${order.delivery.delivery_type === 'cdek' ? 'active_cdek' : ''} ${order.delivery.delivery_type === 'boxberry' ? 'active_boxberry' : ''} ${order.delivery.delivery_type === 'pochta' ? 'active_pochta' : ''}`}>
											<button onClick={()=>{DeliveryFind()}}>Отследить</button>
										</div>

									</div>
									<div className='table_current_full_info_defrayment tcfiw'>
										<h3>Оплата:</h3>
										<p><span className="table_order_bottom_right_span_p"> Оплата: </span> {parseFloat(order.payments.Amount)/100} ₽</p>
										<p>
											<span className="table_current_order_bottom_right_span_p"> Способ оплаты: </span>
											Банковская карта
										</p>

										<p><span className="table_current_order_bottom_right_span_p"> Карта: </span>Карта {order.payments.Pan}</p>
									</div>
								</div>
								{order.status==="CREATED"?<div className='table_current_btn_сancellations'><button onClick={()=>{CancelOrder()}} >Отменить заказ</button></div>:<></>}
								
							</div>
						</div>
					</div>


				</>
			)}
		</>
	)
}
