import React, { useEffect, useState } from 'react'
import "./account.css"
import { AxiosAuthGet, AxiosAuthPost } from '../../services/AxiosRequests';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
export default function Account() {
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [emailBuf, setEmailBuf] = useState('');
	const [phone, setPhone] = useState('');
	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isEmailCodeSend, setisEmailCodeSend] = useState(false);
	const [isInputEmailDisabled, setIsInputEmailDisabled] = useState(false);
	const [codeEmail, setCodeEmail] = useState('');

	async function sendRequest(data) {

		const response = await AxiosAuthPost('/Users/ProfileDataEdit', data)
		if (response.status !== 200) {
			console.error(response.error);
		}
	};
	

	async function handleConfirmationClick() {
		setisEmailCodeSend(true)
		const response = await AxiosAuthPost('/Users/request_confirmation_code', { "new_email": emailBuf })
		if (response.status === 200) {
			alert('Вам выслан код подтверждения на почту, введите его и нажмите кнопку подтверждения')
		}
		else {
			alert('Ошибка сервера, перезагрузите страницу')
		}

	};

	async function handleConfirmationCode() {
		try {
			const response = await AxiosAuthPost('/Users/confirm_email', { "code": codeEmail })
			if (response.status === 200) {
				window.location.reload()
			}
		}
		catch {
			alert('Введенный код неверен')
		}
	};

	useEffect(() => {
		const delay = 1000; // Задержка в миллисекундах
		const timeoutId = setTimeout(() => {
			sendRequest({ "name": name }); // Отправить запрос после задержки
		}, delay);

		// Очистить таймаут при каждом новом вводе текста
		return () => clearTimeout(timeoutId);
	}, [name]);

	useEffect(() => {
		const delay = 1000; // Задержка в миллисекундах
		const timeoutId = setTimeout(() => {
			sendRequest({ "surname": surname }); // Отправить запрос после задержки
		}, delay);

		// Очистить таймаут при каждом новом вводе текста
		return () => clearTimeout(timeoutId);
	}, [surname]);

	useEffect(() => {
		async function fetchdata() {
			try {
				const response = await AxiosAuthGet('/Users/ProfileDataView');
				setName(response.data.name);
				setSurname(response.data.surname);
				setEmail(response.data.email);
				setEmailBuf(response.data.email);
				setPhone(response.data.phone);
			}
			catch {
				window.location.replace('/');
			}
		};
		fetchdata();
	}, []);

	useEffect(() => {
		async function fetchdata() {
			try {
				const response = await AxiosAuthGet('/Order/OrdersUser');
				setOrders(response.data);
				setLoading(false);
			}
			catch {
				window.location.replace('/');
			}
		};
		fetchdata();
	}, []);

	return (
		<>
			<div className="account">
				<div className="information">
					<div className="im_fam">

						<div className="name">
							<div className="name_text" id="param1">
								<p>Имя</p>
								<img src="static/checkmark.svg" className="checkmark" alt="checkmark" />
							</div>
							<div className="name_input">
								<input type="text" value={name} className="input_text_name" onChange={(e) => { setName(e.target.value) }} />
								{/* <img src="static/closesquare.svg" className="img_text_name" /> */}
							</div>
						</div>

						<div className="lastname">
							<div className="lastname_text">
								<p>Фамилия</p>
								<img src="static/checkmark.svg" className="checkmark" alt="checkmark" />
							</div>
							<div className="name_input">
								<input type="text" value={surname} className="input_text_name" onChange={(e) => { setSurname(e.target.value) }} />
								{/* <img src="static/closesquare.svg" className="img_text_name" /> */}
							</div>
						</div>

					</div>
					<div className="info_fio">
						<p>Проверьте правильность ввода личных данных, они необходимы для получения и оформления заказа</p>
					</div>
					<div className="email_item" id="param2">
						<div className="email">
							<p>Электронная почта</p>
							<img src="static/checkmark.svg" className="checkmark" alt="checkmark" />
						</div>
						<div className="email_input">
							<input type="text" value={emailBuf} className="input_text_email" onChange={(e) => { setEmailBuf(e.target.value); }} disabled={isInputEmailDisabled} />
							{email !== emailBuf && (<button onClick={() => {
								if (!isEmailCodeSend) {
									handleConfirmationClick(); setIsInputEmailDisabled(true);
								}
								else {
									handleConfirmationCode();
								}
							}}>Подтвердить изменения</button>)}
							{isEmailCodeSend && (<input value={codeEmail} onChange={(e) => { setCodeEmail(e.target.value) }}></input>)}
						</div>
					</div>
					<div className="info_fio">
						<p>Получайте информацию о состоянии статуса ваших заказов</p>
					</div>
					<div className="phone_number_account" id="param3">
						<div className="phone_text">
							<p>Номер телефона</p>
							<img src="static/checkmark.svg" className="checkmark" alt="checkmark" />
						</div>
						<div className="email_input">
							<input type="tel" className="input_text_phone" value={phone} disabled />

						</div>
					</div>
					<div className="info_fio">
						<p>Номер телефона необходим для оформления заказа</p>
					</div>
					<div className="adress" id="param4">
					</div>
					<p className="zakaz" id="param5">Заказы</p>
					<div className='table_orders'>
						{loading ? (
							<>Загрузка</>
						) : (
							<>
								{orders.length > 0 ? (
									<>
										{orders.map((item, index) => (
											<div className='table_order'>
												<div className='table_order_top'>
													<div className='table_order_top_left'>
														<NavLink to={'/Account/Order/' + item.order_number} >Заказ № {item.order_number} </NavLink>
														<span>{item.status_ru}</span>
													</div>
													<div className='table_order_top_right'>
														<NavLink to={'/Account/Order/' + item.order_number}><button>Подробнее</button></NavLink>
													</div>
												</div>
												<div className='table_order_bottom'>
													<div className='table_order_bottom_left'>
														{item.orderitem.map((order_item, index) => (
															<div className='table_order_bottom_left_item'>
																<div className='table_order_bottom_left_img'>
																	<img src={process.env.REACT_APP_IMG_URL + order_item.product.photo} alt={order_item.product.name + index} />
																</div>
																<div className='table_order_bottom_left_data'>
																	<h5><NavLink to={'/Shoes/' + order_item.product.slug}>{order_item.product.name}</NavLink></h5>
																	<p>Размер -{' ' + order_item.price.size.size_ru}</p>
																	{order_item.price.discount_flag ?
																		(
																			<div className='table_order_bottom_left_data_price'>
																				<p>Цена - </p>
																				<h2 className="table_order_bottom_left_data_price_actualprice">{order_item.price.discount_price} ₽</h2>
																				<s className="table_order_bottom_left_data_price_notactualprice">{order_item.price.end_price} ₽</s>
																				<p className="table_order_bottom_left_data_price_discount">-{order_item.price.discount_percent}%</p>
																			</div>
																		)
																		:
																		(
																			<div className='table_order_bottom_left_data_price'>
																				<p>Цена - </p>
																				<h2 className="table_order_bottom_left_data_price_actualprice">{order_item.price.end_price} ₽</h2>
																			</div>
																		)
																	}
																	<p>Кол-во - {order_item.quantity} </p>
																</div>
															</div>
														))}
													</div>
													<div className='table_order_bottom_right'>
														<p><span className="table_order_bottom_right_span_p">Дата оформления:</span> {moment(item.created_at).locale('ru').format('D MMMM YYYY [года]')}</p>
														<p><span className="table_order_bottom_right_span_p"> Оплата: </span> {parseFloat(item.payments.Amount) / 100} ₽</p>
														<p>
															<span className="table_order_bottom_right_span_p"> Способ оплаты: Карта </span>
															{item.payments.Pan}
														</p>
														{item.delivery ? <p><span className="table_order_bottom_right_span_p">Адресс пвз: </span>{item.delivery.pvz_address}</p> : <></>}
													</div>
												</div>
											</div>
										))}
									</>
								) : (
									<p className="zakaz_second">Заказов нет</p>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
