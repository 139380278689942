import React from 'react'
import './CategoryPopup.css'
import { useEffect,useState } from 'react'
import { AxiosGet } from '../../../../../../services/AxiosRequests'
import { NavLink } from 'react-router-dom';
export default function CategoryPopup({ isCategoryPopupOpen, setIsCategoryPopupOpen }) {
	const [categorys,setCategorys] = useState([]);
	useEffect(() => {
		async function fetchData() {
			const categorys = await AxiosGet('/Store/categorys')
			setCategorys(categorys.data.categorys)
		};
		fetchData();
	}, [])
	return (
		<>
			
				<div className={isCategoryPopupOpen ? 'CategoryPopup active' : 'CategoryPopup'} onMouseLeave={() => { setIsCategoryPopupOpen(false) }}>
					<div className='CategoryPopup_content'>
					<div className='CategoryPopup_gender'>
						<h2>
							Пол
						</h2>
						<p><NavLink onClick={()=>{setIsCategoryPopupOpen(false)}} to={'/shoes/filter/gender=male&page=1'} >Мужская </NavLink></p>
                        <p><NavLink onClick={()=>{setIsCategoryPopupOpen(false)}} to={'/shoes/filter/gender=female&page=1'} >Женская </NavLink></p>
                        <p><NavLink onClick={()=>{setIsCategoryPopupOpen(false)}} to={'/shoes/filter/gender=unisex&page=1'} >Унисекс </NavLink></p>
					</div>

                    <div className='CategoryPopup_subbrands'>
						<h2>
							Тип
						</h2>
						<div className='CategoryPopup_subbrands_list'>
						{categorys.map((category)=>(
							<p><NavLink onClick={()=>{setIsCategoryPopupOpen(false)}} to={'/shoes/filter/category='+category.en+'&page=1'} >{category.ru}</NavLink></p>
						))}
						</div>
					</div>

					<div className='CategoryPopup_brands_photo'>
						
					</div>
					</div>
				</div>
				
		</>
	)
}
