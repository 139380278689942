import { observable, action, makeObservable } from 'mobx';
import { checkAuthentication } from '../services/AuthService';
import { AxiosAuthGet,AxiosAuthPost } from '../services/AxiosRequests';
class Cart {
	cart = [];
    total_count = 0;
    total_quantity = 0;
    total_price = 0;
	constructor() {
        makeObservable(this, {
			cart: observable,
            total_count: observable,
            total_quantity: observable,
            total_price: observable,
			InitCart:action,
            AddItem: action,
            UpdateTotalPrice:action,
            ChangeItemCount:action,
            DeleteItemCart:action,

		});
        this.InitCart();
	}
	async InitCart(){
        const IsAuth = await checkAuthentication();
        if (IsAuth){
            const response = await AxiosAuthGet('/Cart/view_cart')
            this.total_count=response.data.length;
            this.cart=response.data;
            this.total_quantity= this.cart.reduce((accumulator, currentItem) => accumulator + currentItem.count, 0);
            this.UpdateTotalPrice()
            localStorage.setItem('cart', JSON.stringify(this.cart));    
        }
        else{
            const savedCart = localStorage.getItem('cart');
            if (savedCart) {        
                this.cart = JSON.parse(savedCart);
            }
            this.total_count=this.cart.length;
            this.UpdateTotalPrice()
            this.total_quantity= this.cart.reduce((accumulator, currentItem) => accumulator + currentItem.count, 0);
        }
        
    }
	async AddItem(cartItemData) {
        const IsAuth = await checkAuthentication();
        const totalCount = this.cart.reduce((accumulator, currentItem) => accumulator + currentItem.count, 0);
        if (IsAuth){
            if(totalCount<5){
                if (this.cart.find(obj =>obj.price.id===cartItemData.price.id)===undefined){
                    cartItemData['count'] = 1;
                    this.cart.push(cartItemData);
                    try{
                        const response = await AxiosAuthPost('/Cart/add_to_cart',{'price':cartItemData.price.id})
                        if (response.status===200){
                            
                            this.cart = response.data;
                        }
                        else{
                            alert('Ошибка добавления объекта в корзину1, обратитесь к администрации сайта');    
                        }
                    }
                    catch{
                        alert('Ошибка добавления объекта в корзину, обратитесь к администрации сайта')
                    }
                }
                else{
                    const IndexItem = this.cart.findIndex(obj =>obj.price.id===cartItemData.price.id);
                    this.cart[IndexItem]['count']=2;
                    try{
                        const response = await AxiosAuthPost('/Cart/add_to_cart',{'price':cartItemData.price.id})
                        if (response.status===200){
                            
                            this.cart = response.data;
                        }
                        else{
                            alert('Ошибка добавления объекта в корзину1, обратитесь к администрации сайта');    
                        }
                    }
                    catch{
                        alert('Ошибка добавления объекта в корзину, обратитесь к администрации сайта')
                    }
                    
                }
            }
            else{
                alert('Достигнуто максимальное кол-во в рамках 1 заказа')
            }
        }
        else{
            if(totalCount<5){
                if (!this.cart.find(obj =>obj.price.id===cartItemData.price.id)){
                    cartItemData['count'] = 1;
                    this.cart.push(cartItemData);
                    
                }
                else{
                    const IndexItem = this.cart.findIndex(obj =>obj.price.id===cartItemData.price.id);
                    this.cart[IndexItem]['count']=2;
                    
                }
            }
            else{
                alert('Достигнуто максимальное кол-во в рамках 1 заказа')
            }
        }
        this.total_count=this.cart.length
        this.total_quantity= this.cart.reduce((accumulator, currentItem) => accumulator + currentItem.count, 0);
        this.UpdateTotalPrice()
        localStorage.setItem('cart', JSON.stringify(this.cart));
    }
    async DeleteItemCart(){
        const IsAuth = await checkAuthentication();
        if (IsAuth){
            this.cart = [];
            const response = await AxiosAuthPost('/Cart/delete_item_cart')
            if(response.status){
                
            }
        }
        else{
            this.cart = [];
        }
        this.total_count=this.cart.length;
        this.UpdateTotalPrice()
        localStorage.setItem('cart', JSON.stringify(this.cart));
    }   
    async ChangeItemCount(cartItemData,type){
        const IsAuth = await checkAuthentication();
        const totalCount = this.cart.reduce((accumulator, currentItem) => accumulator + currentItem.count, 0);
        if (IsAuth){
            if (type==='plus'){
                if(totalCount<5){
                    const IndexItem = this.cart.findIndex(obj =>obj.price.id===cartItemData.price.id);
                    if (this.cart[IndexItem]['count']<2){
                        this.cart[IndexItem]['count']=2
                        try{
                            const response = await AxiosAuthPost('/Cart/add_cart_item_quantity',{'price':cartItemData.price.id,action:'plus'})
                            if (response.status===200){
                                this.cart = response.data;
                            }
                            else{
                                alert('Ошибка добавления объекта в корзину1, обратитесь к администрации сайта');    
                            }
                        }
                        catch{
                            alert('Ошибка добавления объекта в корзину, обратитесь к администрации сайта')
                        }
                    }
                    else{
                        alert('Достигнуто максимальное кол-во в рамках 1 заказа')
                    }
                }
                else{
                    alert('Достигнуто максимальное кол-во в рамках 1 заказа')
                }
            }
            else{
                const IndexItem = this.cart.findIndex(obj =>obj.price.id===cartItemData.price.id);
                if (this.cart[IndexItem]['count']-1>0){
                    this.cart[IndexItem]['count'] = this.cart[IndexItem]['count']-1
                    try{
                        const response = await AxiosAuthPost('/Cart/add_cart_item_quantity',{'price':cartItemData.price.id,action:'minus'})
                        if (response.status===200){
                            this.cart = response.data;
                        }
                        else{
                            alert('Ошибка добавления объекта в корзину1, обратитесь к администрации сайта');    
                        }
                    }
                    catch{
                        alert('Ошибка добавления объекта в корзину, обратитесь к администрации сайта')
                    }
                }
                else{
                    if (IndexItem !== -1) {
                    this.cart.splice(IndexItem, 1);
                    }
                    try{
                        const response = await AxiosAuthPost('/Cart/add_cart_item_quantity',{'price':cartItemData.price.id,action:'minus'})
                        if (response.status===200){
                            this.cart = response.data;
                        }
                        else{
                            alert('Ошибка добавления объекта в корзину1, обратитесь к администрации сайта');    
                        }
                    }
                    catch{
                        alert('Ошибка добавления объекта в корзину, обратитесь к администрации сайта')
                    }
                }
            }
        }
        else{
            if (type==='plus'){
                if(totalCount<5){
                    const IndexItem = this.cart.findIndex(obj =>obj.price.id===cartItemData.price.id);
                    if (this.cart[IndexItem]['count']<2){
                        this.cart[IndexItem]['count']=2
                    }
                    else{
                        alert('Достигнуто максимальное кол-во в рамках 1 заказа')
                    }
                }
                else{
                    alert('Достигнуто максимальное кол-во в рамках 1 заказа')
                }
            }
            else{
                const IndexItem = this.cart.findIndex(obj =>obj.price.id===cartItemData.price.id);
                if (this.cart[IndexItem]['count']-1>0){
                    this.cart[IndexItem]['count'] = this.cart[IndexItem]['count']-1
                }
                else{
                    if (IndexItem !== -1) {
                    this.cart.splice(IndexItem, 1);
                    }
                }
            }
        }
        this.total_count=this.cart.length;
        this.total_quantity= this.cart.reduce((accumulator, currentItem) => accumulator + currentItem.count, 0);
        this.UpdateTotalPrice()
        localStorage.setItem('cart', JSON.stringify(this.cart));
    }
    UpdateTotalPrice(){
        var buf = 0;
        this.cart.map((item)=>{
            if(item.price.discount_flag){
                buf = buf + item.price.discount_price * item.count;
            }
            else{
                buf = buf + item.price.end_price * item.count;
            }
            return 0
        })
        this.total_price = buf;
    }
}

const CartStore = new Cart();

export default CartStore;

