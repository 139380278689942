import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SizeButton from './SizeButton';

import { AxiosGet } from '../../services/AxiosRequests';
import "./Prodpage.css";
import ProductItem from '../ProductItem/ProductItem';
import CartStore from '../../Stores/Cart';
function Prodpage() {
	const [shoes1, setShoes1] = useState([]);
	const [recomendation, setRecomendation] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activeButton, setActiveButton] = useState(null);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [activeSizeType, setActiveSizeType] = useState('ru');
	const [isDetalizationActive,setIsDetalizationActive] = useState(false);
	const { Slug } = useParams();

	useEffect(() => {
		async function fetchData1() {
			const resp = await AxiosGet('/Store/RecomendationShoes');
			setRecomendation(resp.data);
			try {
				const response = await AxiosGet('/Store/Product/' + Slug);
				if (response.status === 200) {
					setShoes1({ ...response.data, photo: response.data.photo.sort((a, b) => (a.main ? -1 : b.main ? 1 : 0)) });
					setLoading(false);
				}
			} catch (error) {
				console.error('Произошла ошибка:', error);
				setLoading(false);
			}

		}
		fetchData1();
	}, [Slug]);

	function slide_image(number){
		
		if(currentImageIndex+number%shoes1.photo.length===-1){
			setCurrentImageIndex(shoes1.photo.length-1)
		}
		else{
			if (shoes1.photo.length===currentImageIndex+number%shoes1.photo.length){
				setCurrentImageIndex(0);
			}
			else{
				setCurrentImageIndex(currentImageIndex+number%(shoes1.photo.length-1));
			}
		}
	}

	const handleButtonSizeTypeClick = (button) => {
		setActiveSizeType(button);
	};

	const handleButtonClick = (buttonId) => {
		setActiveButton(buttonId);
	};

	const changeImage = (index) => {
		setCurrentImageIndex(index);
	};

	const handleMouseEnter = (index) => {
		setCurrentImageIndex(index);
	};

	const handleMouseLeave = () => {
		// Добавьте логику, если необходимо обработать событие ухода курсора
	};

	async function handleButtonAddCart() {
		if (activeButton === null) {
			alert('Выберите размер перед добавлением в корзину');
			return;
		}

		if (CartStore.cart.find(obj => obj.price.id === shoes1.price.find(item => item.size.id === activeButton).id) === undefined) {
			const cartItemData = {
				name: shoes1.name,
				size: shoes1.price.find(item => item.size.id === activeButton).size.size_ru,
				img: shoes1.photo[0].img,
				price: shoes1.price.find(item => item.size.id === activeButton),
				gender: shoes1.gender,
				slug:shoes1.slug
			}
			CartStore.AddItem(cartItemData)


		}
		else {
			if (CartStore.cart.find(obj => obj.price.id === shoes1.price.find(item => item.size.id === activeButton).id).count < 2) {
				const cartItemData = {
					price: shoes1.price.find(item => item.size.id === activeButton),
				}
				CartStore.AddItem(cartItemData)

			}
			else {
				setActiveButton(null);
				alert('Это максимальное количество доступное для заказа данного размера')
				return
			}
		}
		setActiveButton(null);
	};

	return (
		<>
			{loading ? (
				<center>Загрузка данных...</center>
			) : (
				<>
					<div className='product_content'>
						<div className="product">
							<div className="left_side">

								<div className="shoes_img">
									<div className='shoes_img_right_arrow'><button onClick={()=>{slide_image(-1);}} disabled={shoes1.photo.length===1}><img src='/static/left_arrow.svg' alt=''></img></button></div>
									<div className='shoes_img_current'>
										<img
											className="big_shoes"
											src={process.env.REACT_APP_IMG_URL + shoes1.photo[currentImageIndex].img}
											alt="big_shoes"
										/>
									</div>
									<div className='shoes_img_right_arrow'><button onClick={()=>{slide_image(+1);}} disabled={shoes1.photo.length===1}><img src='/static/right_arrow.svg' alt=''></img></button></div>
								</div>

								<div className="min_shoes_img">
									{shoes1.photo.map((item, index) => (
										<img
											key={index}
											className={`min_shoes_item ${currentImageIndex === index ? 'active' : ''}`}
											src={process.env.REACT_APP_IMG_URL + item.img}
											alt="min_shoes"
											onClick={() => changeImage(index)}
											onMouseEnter={() => handleMouseEnter(index)}
											onMouseLeave={handleMouseLeave}
										/>
									))}
								</div>
							</div>

							<div className="right_side">
								<div className="addtobasket_cont">
									<div className='article_flex'>
										<p className='article'>Артикул:</p>
										<p className='article_numbers'>{shoes1.external_article}</p>
									</div>
									<h1 className="nameshoes">{shoes1.name}</h1>

									<div className="pp_price">
										{activeButton ? (
											<>
												{shoes1.price.find((pri) => pri.size.id === activeButton).discount_flag ?
													(
														<>
															<h2 className="actualprice">{shoes1.price.find((pri) => pri.size.id === activeButton).discount_price} ₽</h2>
															<s className="notactualprice">{shoes1.price.find((pri) => pri.size.id === activeButton).end_price} ₽</s>
															<p className="discount">-{shoes1.price.find((pri) => pri.size.id === activeButton).discount_percent}%</p>
														</>
													)
													:
													(
														<>
															<h2 className="actualprice">{shoes1.price.find((pri) => pri.size.id === activeButton).end_price} ₽</h2>
														</>
													)
												}
											</>
										) : (
											<>
												{shoes1.price[0].discount_flag ?
													(
														<>
															<h2 className="actualprice">{shoes1.price[0].discount_price} ₽</h2>
															<s className="notactualprice">{shoes1.price[0].end_price} ₽</s>
															<p className="discount">-{shoes1.price[0].discount_percent}%</p>
														</>
													)
													:
													(
														<>
															<h2 className="actualprice">{shoes1.price[0].end_price} ₽</h2>
														</>
													)
												}
											</>
										)}


									</div>
									<div className="sizeshoes">
										<p>Размеры:</p>
										<div className='size_type'>
											<button className={activeSizeType === 'us' ? 'activeTypeSize' : ''} onClick={() => handleButtonSizeTypeClick('us')}>US</button>
											<button className={activeSizeType === 'uk' ? 'activeTypeSize' : ''} onClick={() => handleButtonSizeTypeClick('uk')}>UK</button>
											<button className={activeSizeType === 'eu' ? 'activeTypeSize' : ''} onClick={() => handleButtonSizeTypeClick('eu')}>EU</button>
											<button className={activeSizeType === 'ru' ? 'activeTypeSize' : ''} onClick={() => handleButtonSizeTypeClick('ru')}>RU</button>
											<button className={activeSizeType === 'cm' ? 'activeTypeSize' : ''} onClick={() => handleButtonSizeTypeClick('cm')}>CM</button>
										</div>
										<div className="sizeshoes_cont">
											{shoes1.price.map((item) => (
												<div className="sizeshoes_item" key={item.size.id}>
													<SizeButton
														size={item.size['size_' + activeSizeType]}
														active={activeButton === item.size.id}
														onClick={() => handleButtonClick(item.size.id)}
													/>
												</div>
											))}

										</div>
										<div className="tableofsize">
										</div>

									</div>
									<div className="btn_addtobasket">
										<button onClick={() => { handleButtonAddCart() }}>Добавить в корзину</button>
									</div>
								</div>

							</div>
						</div>
						<div className="descriptionshoes">
							<div className="desc">
								<h2 className="desc_h2">Описание:</h2>
								<p className="desc_p">{shoes1.characteristic[0].text}</p>
							</div>
						</div>
						<div className='detalization'>
							<button className={isDetalizationActive ? 'detalization_button active':'detalization_button'} onClick={()=>{setIsDetalizationActive(!isDetalizationActive)}}>
							<p>Подробнее</p>
								{isDetalizationActive ? <img src='/static/footer_up_arrow_black.svg' alt=''/> :
								<img src='/static/footer_down_arrow_black.svg' alt='' /> }
							</button>
							{isDetalizationActive ?
							(
								<div className='detalization_text'>
								<p>
								Товары, представленные на сайте, являются качественной репликой категории Premium.
								Premium - это высокое качество, и долгий срок службы. Изготовлены из оригинальных материалов с соблюдением всех технологий пошива.
								</p>
								</div>
							):(<></>)}
						</div>
						<div className='page_recomendation'>
							<p className='page_recomendation_name'>Вам может понравится</p>
							<div className='recomendation'>
								{recomendation.map((item, index) => (
									<ProductItem item={item} key={index}></ProductItem>
								))}
							</div>
						</div>
					</div>
				</>

			)}

		</>
	);
}

export default Prodpage;