import React, { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import './main.css'
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import { AxiosGet } from '../../../services/AxiosRequests';
import { Link, animateScroll as scroll } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import FilterDesktop from '../FilterDesktop/FilterDesktop';
import FilterMobile from '../FilterMobile/FilterMobile';
import ProductItem from '../../ProductItem/ProductItem';
function Buttons(CountPage, CurrentPage) {
	const buttons = [];

	for (let i = 1; i <= CountPage; i++) {
		buttons.push(
			<div className={`paginations_size ${i.toString() === CurrentPage ? 'selected' : ''}`}>
				<NavLink to={'/shoes/page/' + i}><button disabled={i.toString() === CurrentPage} >{i}</button></NavLink>
			</div>

		);
	}

	return buttons;
}


export default function ShoesMain() {
	const [shoes1, setShoes1] = useState([]);
	const [CountPage, setCountPage] = useState();
	const [loading, setLoading] = useState(true);
	const [isFilterMobileOpen, setIsFilterMobileOpen] = useState(false);
	useEffect(() => {
		async function fetchData1() {
			try {

				const response = await AxiosGet('/Store/AllShoes');
				if (response.status === 200) {
					console.log(response.data);
					setShoes1(response.data.results);
					setCountPage(response.data.num_pages);
					setLoading(false);
				}
			}
			catch (error) {
				console.error('Произошла ошибка:', error);
			}
		}
		scroll.scrollToTop({
			duration: 300, // You can adjust the duration as needed
			smooth: 'easeInOutQuad', // You can adjust the easing function
		});
		fetchData1();
	}, []);

	return (
		<>
			{loading ? (
				<LoadingAnimation>
				</LoadingAnimation>
			) : (
				<>
					<div className={window.innerWidth > 768 ? 'shoes_main' : 'shoes_main mobile'}  >
						{window.innerWidth > 768 ?
							<div className='filter_desktop'>
								<FilterDesktop />
							</div>
							:
							<div className='filter_mobile'>
								<button className='filter_mobile_button' onClick={() => { setIsFilterMobileOpen(!isFilterMobileOpen) }}>
									<img src='/static/filter.svg' alt='' />
									<p>Фильтр</p>
								</button>

								<FilterMobile isActive={isFilterMobileOpen} setIsActive={setIsFilterMobileOpen} />
							</div>
						}
						<div className='shoes_products'>
							<Link to="top" smooth={true} duration={1000}></Link>
							<div className="shoes_page_items">
								{shoes1.map((item, index) => (
									<div className='shoes_page_item'>
										<ProductItem item={item} key={index} />
									</div>

								))}
							</div>

							<div className='paginations_sizes'>
								{Buttons(CountPage, "1")}
							</div>
						</div>
					</div>

				</>
			)}
		</>
	);
}

