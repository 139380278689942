import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ImageSlider from './slider';
import ProductItem from '../ProductItem/ProductItem';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import "./Main.css"
import { AxiosGet } from '../../services/AxiosRequests';

function Main() {
	const [shoes, setShoes] = useState([]);
	const [shoes_sale, setShoesSale] = useState([]);
	const [shoes_male, setShoesMale] = useState([]);
	const [shoes_female, setShoesFemale] = useState([]);
	const [shoes_unisex, setShoesUnisex] = useState([]);
	const [shoes_nike, setShoesNike] = useState([]);
	const [shoes_new_balance, setShoesNewBalance] = useState([]);
	const [shoes_adidas, setShoesAdidas] = useState([]);
	const [shoes_vans, setShoesVans] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Функция для выполнения GET запроса к API
		async function fetchData1() {
			try {

				const shoes = await AxiosGet('/Store/MainPageShoes');
				console.log(shoes.data)
				setShoes(shoes.data.shoes);
				setShoesSale(shoes.data.shoes_sale);
				setShoesMale(shoes.data.shoes_male);
				setShoesFemale(shoes.data.shoes_female);
				setShoesUnisex(shoes.data.shoes_unisex);
				setShoesNike(shoes.data.shoes_nike);
				setShoesAdidas(shoes.data.shoes_adidas);
				setShoesNewBalance(shoes.data.shoes_new_balance);
				setShoesVans(shoes.data.shoes_vans);
				setLoading(false);
			} 
			catch (error) {
				console.error('Произошла ошибка:', error);
			}
		}
		
		fetchData1();
	}, []);
	return (
		<>
			{loading ? (
				<LoadingAnimation>
				</LoadingAnimation>
			):
			(
				<>
				<ImageSlider></ImageSlider>
				
				<div>
					
				<div className="main_items">
					<div className="main_shoes"><NavLink to={"/shoes/filter/sale=true&page=1"}>Распродажа</NavLink></div>
					<div className="sale_cont">
					{shoes_sale.map((item, index) => (
						<div className="sale_cont_item"> 
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"/shoes"} >Вся обувь</NavLink></div>
					<div className="sale_cont">
					{shoes.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>
				
				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/gender=male&page=1"} >Мужская обувь</NavLink></div>
					<div className="sale_cont">
					{shoes_male.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/gender=female&page=1"} >Женская обувь</NavLink></div>
					<div className="sale_cont">
					{shoes_female.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/gender=unisex&page=1"} >Унисекс обувь</NavLink></div>
					<div className="sale_cont">
					{shoes_unisex.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/brand=Nike&page=1"} >Nike</NavLink></div>
					<div className="sale_cont">
					{shoes_nike.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/brand=Adidas&page=1"} >Adidas</NavLink></div>
					<div className="sale_cont">
					{shoes_adidas.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/brand=New Balance&page=1"} >New Balance</NavLink></div>
					<div className="sale_cont">
					{shoes_new_balance.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				<div className="main_items">
					<div className="main_shoes"><NavLink to={"shoes/filter/brand=Vans&page=1"} >Vans</NavLink></div>
					<div className="sale_cont">
					{shoes_vans.map((item, index) => (
						<div className="sale_cont_item">
							<ProductItem item={item} key={index}></ProductItem>
						</div>
					))}
					</div>
				</div>

				</div>
				</>
			)}
			

			
		</>
	);
}

export default Main;