import React, { useState,useRef } from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom';
export default function Footer() {
	const [isInformationOpen, setIsInformationOpen] = useState(false);
	const [isContactOpen, setIsContactOpen] = useState(false);
	const mobileInfoRef = useRef(null);

	return (
		<>
			<footer>
				<div className='desktop_footer'>
					<div className='desktop_footer_line'>
						<div className='desktop_footer_1'>
							<h3>Разделы</h3>
							<NavLink to={'/sale'}><p>Sale</p></NavLink>
							<NavLink to={'/shoes'}><p>Обувь</p></NavLink>
							<NavLink to={'/'}><p>Одежда и аксессуары</p></NavLink>
						</div>
						<div className='desktop_footer_2'>
							<h3>Информация</h3>
							<NavLink to={'/delivery'}><p>Доставка</p></NavLink>
							<NavLink to={'/payment'}><p>Оплата</p></NavLink>
							<NavLink to={'/refund'}><p>Возврат</p></NavLink>
						</div>
						<div className='desktop_footer_3'>
							<NavLink to={'/Contacts'}><h3>Контакты</h3></NavLink>
							<div className='desktop_footer_contact_content_phone'>
								<img src='/static/footer_phone_white.svg' alt='phone' />
								<a href="tel:+79252247922"><p>+7 (925) 224-79-22</p></a>
							</div>
							<div className='desktop_footer_contact_content_phone'>
								<img src='/static/footer_email_white.svg' alt='email' />
								<a href="mailto:info@kanturstore.ru"><p>info@kanturstore.ru</p></a>
							</div>
							<div className='desktop_footer_contact_content_phone'>
								<img src='/static/footer_location_white.svg' alt='location' />
								<p>г. Москва, ул. Проспект Андропова, д. 22</p>
							</div>
							<NavLink to={'/privacy-policy'}><p>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</p></NavLink>
							<NavLink to={'/user-agreement'}><p>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</p></NavLink>
							<NavLink to={'/offer-agreement'}><p>ДОГОВОР ОФЕРТЫ</p></NavLink>
						</div>
						<div className='desktop_footer_4'>
							<a href='https://www.instagram.com/kantur.store'><img src="/static/insta_icon.svg" alt="avito" /></a>
							<a href='https://api.whatsapp.com/send/?phone=79252247922'><img src="/static/whatsapp.svg" alt="whatsapp" className="whatsapp" /></a>
							<a href='https://t.me/kanturstore'><img src="/static/telega.svg" alt="telegram" className="telega" /></a>
							<a href='https://vk.com/kantur_store'><img src="/static/vk.svg" alt="vk" className="vk" /></a>
							{/* <img src="/static/instagram.svg" alt="inst" className="inst" /> */}
						</div>
					</div>
					<div className='desktop_footer_line'>
						<p className="copyright">2024 c. KanturStore</p>
					</div>
				</div>
				<div className='mobile_footer'>

					<div className={!isInformationOpen ? 'mobile_footer_information' : 'mobile_footer_information active'}>
						<button onClick={() => { setIsInformationOpen(!isInformationOpen) }}>
							<p>Информация</p>
							{!isInformationOpen ? <img src='/static/footer_down_arrow.svg' alt='arrow' /> : <img src='/static/footer_up_arrow.svg' alt='arrow' />}
						</button>
					</div>
					<div className={!isInformationOpen ? 'mobile_footer_information_content' : 'mobile_footer_information_content active'}
					ref={mobileInfoRef}
					>
						<NavLink to={'/delivery'}><p>Доставка</p></NavLink>
						<NavLink to={'/payment'}><p>Оплата</p></NavLink>
						<NavLink to={'/refund'}><p>Возврат</p></NavLink>
						<NavLink to={'/privacy-policy'}><p>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</p></NavLink>
						<NavLink to={'/user-agreement'}><p>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</p></NavLink>
						<NavLink to={'/offer-agreement'}><p>ДОГОВОР ОФЕРТЫ</p></NavLink>
					</div>

					<div className={!isContactOpen ? 'mobile_footer_contact' : 'mobile_footer_contact active'}>
						<button onClick={() => { setIsContactOpen(!isContactOpen) }}>
							<p>Контакты</p>
							{!isContactOpen ? <img src='/static/footer_down_arrow.svg' alt='strelka' /> : <img src='/static/footer_up_arrow.svg' alt='strelka' />}
						</button>
					</div>
					<div className={!isContactOpen ? 'mobile_footer_contact_content' : 'mobile_footer_contact_content active'}>
						<NavLink to={'/Contacts'}><p>Все контакты</p></NavLink>
						<div className='mobile_footer_contact_content_phone'>
							<img src='/static/footer_phone.svg' alt='phone' />
							<a href="tel:+79252247922"><p>+7 (925) 224-79-22</p></a>
						</div>
						<div className='mobile_footer_contact_content_email'>
							<img src='/static/footer_email.svg' alt='email' />
							<a href="mailto:info@kanturstore.ru"><p>info@kanturstore.ru</p></a>
						</div>
						<div className='mobile_footer_contact_content_address'>
							<img src='/static/footer_location.svg' alt='location' />
							<p>г. Москва, ул. Проспект Андропова, д. 22</p>
						</div>
					</div>

					<div className='mobile_footer_copyright'>
						<div className='mobile_footer_copyright_icons'>

							<a href='https://www.instagram.com/kantur.store'><img src="/static/insta_icon.svg" alt="avito" /></a>
							<a href='https://api.whatsapp.com/send/?phone=79252247922'><img src="/static/whatsapp.svg" alt="whatsapp" className="whatsapp" /></a>
							<a href='https://t.me/kanturstore'><img src="/static/telega.svg" alt="telegram" className="telega" /></a>
							<a href='https://vk.com/kantur_store'><img src="/static/vk.svg" alt="vk" className="vk" /></a>

						</div>
						<div className='mobile_footer_copyright_text'>
							<p className="copyright">2024 c. KanturStore</p>
						</div>
					</div>
				</div>

			</footer>
		</>
	)
}