import React from 'react'
import './FilterDesktop.css'
import { useState, useEffect } from 'react';
import { AxiosGet } from '../../../services/AxiosRequests';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useNavigate } from 'react-router-dom';
export default function FilterDesktop({ queryParams }) {
    const [brands, setBrands] = useState([]);
    const [categorys, setCategorys] = useState([]);
    const [colors, setColors] = useState([]);
    const [models, setModels] = useState([]);
    const sizes = [35, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 42, 43, 44, 45];
    const [priceRange, setPriceRange] = useState([0, 30000]);
    const genderTranslate = { 'Мужской': 'male', 'Женский': 'female', 'Унисекс': 'unisex' }

    const [selectedGender, setSelectedGender] = useState(queryParams && queryParams.gender ? queryParams.gender : []);
    const [selectedCategorys, setSelectedCategorys] = useState(queryParams && queryParams.category ? queryParams.category : []);
    const [selectedColors, setSelectedColors] = useState(queryParams && queryParams.color ? queryParams.color : []);
    const [selectedBrands, setSelectedBrands] = useState(queryParams && queryParams.brand ? queryParams.brand : []);
    const [selectedModels, setSelectedModels] = useState(queryParams && queryParams.model ? queryParams.model : []);
    const [selectedSizes, setSelectedSizes] = useState(queryParams && queryParams.size ? queryParams.size : []);
    const [selectedSale, setSelectedSale] = useState(queryParams && queryParams.sale && queryParams.sale[0] ? queryParams.sale[0]==='true' ? true : false : false );

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const brands = await AxiosGet('/Store/brand');
            setBrands(brands.data.brands);
            const categorys = await AxiosGet('/Store/categorys');
            setCategorys(categorys.data.categorys);
            const colors = await AxiosGet('/Store/colors');
            setColors(colors.data.colors);
            const models = await AxiosGet('/Store/models_f');
            setModels(models.data.models);
        };
        fetchData();
    }, []);

    const handlePriceChange = (value) => {
        setPriceRange(value);
    };

    const applyFilters = async () => {
        try {
            const params = new URLSearchParams();

            selectedGender.forEach((gender) => {
                params.append('gender', gender);
            });

            selectedCategorys.forEach((category) => {
                params.append('category', category);
            });
            selectedColors.forEach((color) => {
                params.append('color', color);
            });

            selectedBrands.forEach((brand) => {
                params.append('brand', brand);
            });

            selectedModels.forEach((model) => {
                params.append('model', model);
            });

            selectedSizes.forEach((size) => {
                params.append('size', size);
            });
            params.append('sale', selectedSale);



            params.append('min_price', priceRange[0]);
            params.append('max_price', priceRange[1]);
            navigate('/shoes/filter/' + params.toString() + "&page=1");
            console.log(params.toString());

        } catch (error) {
            console.error('Произошла ошибка при запросе фильтров:', error);
        }
    };

    const handleTooltip = (value) => {
        return `${value} ₽`;
    };

    const handleGenderClick = (gender) => {
        if (selectedGender.includes(genderTranslate[gender])) {
            setSelectedGender(selectedGender.filter((selectedGender) => selectedGender !== genderTranslate[gender]));
        } else {
            setSelectedGender([...selectedGender, genderTranslate[gender]]);
        }
    };

    const handleCategoryClick = (category) => {
        if (selectedCategorys.includes(category)) {
            setSelectedCategorys(selectedCategorys.filter((selectedCategory) => selectedCategory !== category));
        } else {
            setSelectedCategorys([...selectedCategorys, category]);
        }
    };

    const handleColorClick = (color) => {
        if (selectedColors.includes(color)) {
            setSelectedColors(selectedColors.filter((selectedColor) => selectedColor !== color));
        } else {
            setSelectedColors([...selectedColors, color]);
        }
    };

    const handleBrandClick = (brand) => {
        if (selectedBrands.includes(brand)) {
            setSelectedBrands(selectedBrands.filter((selectedBrand) => selectedBrand !== brand));
        } else {
            setSelectedBrands([...selectedBrands, brand]);
        }
    };

    const handleModelClick = (model) => {
        if (selectedModels.includes(model)) {
            setSelectedModels(selectedModels.filter((selectedModel) => selectedModel !== model));
        } else {
            setSelectedModels([...selectedModels, model]);
        }
    };

    const handleSizeClick = (size) => {
        if (selectedSizes.includes(size.toString())) {
            setSelectedSizes(selectedSizes.filter((selectedSize) => selectedSize.toString() !== size.toString()));
        } else {
            setSelectedSizes([...selectedSizes, size.toString()]);
        }
    };

    const handleSaleClick = () => {
        setSelectedSale(!selectedSale)
    };

    useEffect(() => {
		setSelectedGender(queryParams && queryParams.gender ? queryParams.gender : []);
        setSelectedCategorys(queryParams && queryParams.category ? queryParams.category : []);
        setSelectedColors(queryParams && queryParams.color ? queryParams.color : []);
        setSelectedBrands(queryParams && queryParams.brand ? queryParams.brand : []);
        setSelectedModels(queryParams && queryParams.model ? queryParams.model : []);
        setSelectedSizes(queryParams && queryParams.size ? queryParams.size : []);
        setSelectedSale(queryParams && queryParams.sale && queryParams.sale[0] ? queryParams.sale[0]==='true' ? true : false : false );
	}, [queryParams])

    return (
        <div className='desktop_filter_content'>
            <div className='desktop_filter_content_header'>
                <h1>Фильтры</h1>
            </div>
            <div className='desktop_filter_content_confirm'>
                <button onClick={() => { applyFilters() }}>Применить<p>{selectedCategorys.length + selectedBrands.length+selectedColors.length + selectedSizes.length + selectedGender.length + selectedModels.length}</p></button>
                <button onClick={() => { navigate('/shoes') }}>Сборсить</button>
            </div>
            <div className='desktop_filter_content_price'>
                <h3>Цена</h3>
                <div className="desktop_filter_content_price_custom_tooltip">
                    {priceRange.map((value, index) => (
                        <div key={index} className="tooltip-item">
                            {handleTooltip(value)}
                        </div>
                    ))}
                </div>
                <Slider
                    range
                    min={0}
                    max={30000}
                    defaultValue={priceRange}
                    onChange={handlePriceChange}
                    tipFormatter={handleTooltip}
                    trackStyle={{ backgroundColor: "black", height: 4 }}
                    dotStyle={{ backgroundColor: "black", }}
                    pushable
                />
                <div className='desktop_filter_content_price_sale_picker'>
                    <input
                        type='checkbox'
                        onChange={() => { handleSaleClick() }}
                        checked={selectedSale}
                    />
                    <p onClick={()=>{handleSaleClick()}} >{'Только товары по скидке'}</p>
                </div>
            </div>

            <div className='desktop_filter_content_genders'>
                <h3>Пол:</h3>
                {['Мужской', 'Женский', 'Унисекс'].map((gender) => (
                    <div className='desktop_filter_content_gender'>
                        <input
                            type='checkbox'
                            onChange={() => { handleGenderClick(gender) }}
                            checked={selectedGender.includes(genderTranslate[gender])}
                        />
                        <p onClick={()=>{handleGenderClick(gender)}} >{gender}</p>
                    </div>
                ))}
            </div>

            <div className='desktop_filter_content_categorys'>
                <h3>Категории:</h3>
                <div className='desktop_filter_content_categorys_list'>
                    {categorys.map((category) => (
                        <div className='desktop_filter_content_category'>
                            <input
                                type='checkbox'
                                onChange={() => { handleCategoryClick(category.en) }}
                                checked={selectedCategorys.includes(category.en)}
                            />
                            <p onClick={()=>{handleCategoryClick(category.en)}} >{category.ru}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='desktop_filter_content_colors'>
                <h3>Цвета:</h3>
                <div className='desktop_filter_content_colors_list'>
                    {colors.map((color) => (
                        <div className='desktop_filter_content_color'>
                            <input
                                type='checkbox'
                                onChange={() => { handleColorClick(color.en) }}
                                checked={selectedColors.includes(color.en)}
                            />
                            <p onClick={()=>{handleColorClick(color.en)}} >{color.en}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='desktop_filter_content_brands'>
                <h3>Бренды:</h3>
                <div className='desktop_filter_content_brands_list'>
                    {brands.map((brand) => (
                        <div className='desktop_filter_content_brand'>
                            <input
                                type='checkbox'
                                onChange={() => { handleBrandClick(brand) }}
                                checked={selectedBrands.includes(brand)}
                            />
                            <p onClick={()=>{handleBrandClick(brand)}} >{brand}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='desktop_filter_content_models'>
                <h3>Модели:</h3>
                <div className='desktop_filter_content_models_list'>
                    {models.map((model) => (
                        <div className='desktop_filter_content_model'>
                            <input
                                type='checkbox'
                                onChange={() => { handleModelClick(model) }}
                                checked={selectedModels.includes(model)}
                            />
                            <p onClick={()=>{handleModelClick(model)}} >{model}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='desktop_filter_content_sizes'>
                <h3>Размеры:</h3>
                <div className='desktop_filter_content_sizes_list'>
                    {sizes.map((size) => (
                        <div className='desktop_filter_content_size'>
                            <input
                                type='checkbox'
                                onChange={() => { handleSizeClick(size) }}
                                checked={selectedSizes.includes(size.toString())}
                            />
                            <p onClick={()=>{handleSizeClick(size)}} >{size} EU</p>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}
